import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Grid } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '0 1 auto',
      '& > div > div': {
        height: '52.63px',
        borderBottom: '1px solid #E3E6F3',
        display: 'flex',
        alignItems: 'center',
        paddingRight: 20,
      },
    },
    label: {
      position: 'relative',
      zIndex: 1,
      '&:hover': {
        '& $infoIcon': {
          display: 'inline-flex',
        },
      },
    },
    infoIcon: {
      display: 'none',
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
      color: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.text.secondary}`,
      borderRadius: '50%',
      width: 16,
      height: 16,
      fontSize: 10,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        '& $infoText': {
          display: 'inline-block',
        },
      },
    },
    infoText: {
      display: 'none',
      position: 'absolute',
      bottom: 22,
      zIndex: 5,
      width: 'max-content',
      maxWidth: 200,
      backgroundColor: 'white',
      color: theme.palette.common.black,
      fontSize: 10,
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 4,
      padding: theme.spacing(1),
      '&:after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 8,
        height: 8,
        bottom: -5,
        left: 'calc(50% - 4px)',
        backgroundColor: 'white',
        borderRight: `1px solid ${theme.palette.secondary.main}`,
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        transform: 'rotate(45deg)',
      },
    },
  })
);

export const RatingFinancialLabels = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container rowSpacing={0}>
        <Grid item xs={24} className={classes.label}>
          <span>{t('Assets total')}</span>
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Баланс ст.1600</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Чистые нематериальные активы
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Баланс ст.1110</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Внутригрупповые займы &gt; 1 года
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Баланс ст.1170 + подтверждение расшифровкой</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Внутригрупповые займы &lt; 1 года
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Баланс ст.1240 + подтверждение расшифровкой</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Внутригрупповая ДЗ &lt; 1 года
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Всегда ручное значение из расшифровки ДЗ</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Касса и денежные средства на счёте (без ценных бумаг)
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>
              Ручное значение из расшифровки, по умолчянию можно указывать = ст.1250
            </div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Всего денежных средств и инвестиций
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Баланс ст.1250</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Капитал и резервы
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Баланс ст.1300</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Долгосрочные обязательства
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Баланс ст.1410</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Прочие долгосрочные обязательства
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Баланс ст.1450</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Заёмные средства (краткосрочные)
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Баланс ст.1510</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Прочие краткосрочные обязательства
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Баланс ст.1550</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Часть субординированного долга к оплате в ближ. 12 мес.
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Ручное значение из расшифровок</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Денежные средства на счетах участников, как часть капитала
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Ручное значение из расшифровок</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Лизинговые обязательства
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Ручное значение из расшифровок</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Опер. Лизинг
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Ручное значение из расшифровок</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Выручка
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Форма 2 ст. 2110</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Прибыль (убыток) от продажи
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Форма 2 ст. 2200</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Устойчивая EBITDA
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>
              Форма 2 ст. 2200 плюс Амортизация и расходы по забалансовому лизингу (всегда ручные)
            </div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Операционный результат деятельности, подлежащей прекращению
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Ручное значение из расшифровок</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Прибыль (убыток) до налогообложения
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Форма 2 ст. 2300</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Амортизация
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Ручное значение из расшифровок</div>
          </div>
        </Grid>
        <Grid item xs={24} className={classes.label}>
          Расходы по забалансовому лизингу в себестоимости
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>Ручное значение из расшифровок</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
