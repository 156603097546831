import {
  makeStyles,
  createStyles,
  Theme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import React from 'react';
import { formatNumber } from 'components/utils';
import { NomenclaturePriceDrop } from 'schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.text.secondary,
      '& .MuiTableCell-root': {
        color: theme.palette.background.paper,
        paddingTop: 2,
        paddingBottom: 2,
        fontWeight: 400,
        width: 215,
        '&:nth-child(1)': {
          width: 76,
        },
      },
    },
    row: {
      '& .MuiTableCell-root': {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    colored: {
      color: theme.palette.success.main,
      background: theme.palette.success.light,
    },
  })
);

type ApprovalTablePropsType = {
  drops: NomenclaturePriceDrop[];
  monthNumber: number;
  isOpen: boolean;
};

export const ApprovalTable = (props: ApprovalTablePropsType) => {
  const classes = useStyles();

  const { drops, monthNumber, isOpen } = props;

  const dropRows = drops.map((drop) => {
    const coloredClass = monthNumber <= drop.monthNumber ? classes.colored : '';
    const isShow = monthNumber === drop.monthNumber || isOpen;

    return (
      isShow && (
        <TableRow className={classes.row} key={drop.monthNumber}>
          <TableCell size={'medium'} className={coloredClass}>
            {drop.monthNumber}
          </TableCell>
          <TableCell size={'medium'} align="right" className={coloredClass}>
            {formatNumber(drop.mainDebt)}
          </TableCell>
          <TableCell size={'medium'} align="right" className={coloredClass}>
            {drop.priceDropPercents}
          </TableCell>
          <TableCell size={'medium'} align="right" className={coloredClass}>
            {formatNumber(drop.priceDropAmount)}
          </TableCell>
          <TableCell size={'medium'} align="right" className={coloredClass}>
            {formatNumber(drop.priceDropValue)}
          </TableCell>
        </TableRow>
      )
    );
  });

  return (
    <>
      <Table>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell size={'medium'}>№ платежа</TableCell>
            <TableCell size={'medium'} align="right">
              Основной долг остаток
            </TableCell>
            <TableCell size={'medium'} align="right">
              Value lines / %
            </TableCell>
            <TableCell size={'medium'} align="right">
              Стоимость с учетом Value lines
            </TableCell>
            <TableCell size={'medium'} align="right">
              ГЭП
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{dropRows}</TableBody>
      </Table>
    </>
  );
};
