import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { ScoringApprovalViewModel } from 'schema';
import { StatusItem } from './StatusItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      lineHeight: '16px',
    },
    head: {
      display: 'grid',
      gridTemplateColumns: '20px 1fr 1fr 6fr 1fr 1fr 1fr 2fr',
      fontSize: 12,
      padding: theme.spacing(0.25, 2),
      gridGap: theme.spacing(0, 2),
      color: theme.palette.common.white,
      backgroundColor: theme.palette.text.secondary,
    },
    row: {
      display: 'grid',
      gridTemplateColumns: '16px 1fr 1fr 1fr 6fr 1fr 1fr 2fr',
      fontSize: 12,
      padding: theme.spacing(1, 2),
      gridGap: theme.spacing(0, 2),
      color: theme.palette.text.secondary,
    },
  })
);

export type StatusTableProps = {
  rows: ScoringApprovalViewModel[];
};

export const StatusTable = (props: StatusTableProps) => {
  const classes = useStyles();
  const { rows } = props;

  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <div />
        <div>Модель</div>
        <div>Способ</div>
        <div>Решение</div>
        <div>Обработано</div>
        <div>Действует до</div>
        <div>Ответственный</div>
      </div>
      {rows.map((row, index) => (
        <StatusItem row={row} index={index} key={row.id} />
      ))}
    </div>
  );
};
