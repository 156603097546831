import {
  makeStyles,
  createStyles,
  Theme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import {
  ApplicationRole,
  ScoringApprovalViewModel,
  ScoringModelResult,
  ScoringRuleResultViewModel,
} from 'schema';
import clsx from 'clsx';
import { alpha } from '@material-ui/core/styles';
import { useLocation, useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../Breadcrumbs';
import { Role } from '../../authentication';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.text.secondary,
      '& .MuiTableCell-root': {
        color: theme.palette.background.paper,
        paddingTop: 2,
        paddingBottom: 2,
        fontWeight: 400,
        width: 215,
        '&:nth-child(1)': {
          width: 24,
        },
        '&:nth-child(5)': {
          width: 24,
        },
      },
    },
    row: {
      '& .MuiTableCell-root': {
        paddingTop: 8,
        paddingBottom: 8,
        position: 'relative',
      },
    },
    coloredBackA: {
      background: theme.palette.success.light,
    },
    coloredBackX: {
      background: alpha(theme.palette.error.light, 0.5),
    },
    coloredBackN: {
      background: theme.palette.primary.light,
    },
    infoIcon: {
      position: 'relative',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.text.secondary}`,
      borderRadius: '50%',
      width: 16,
      height: 16,
      fontSize: 10,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        '& $infoText': {
          display: 'inline-block',
        },
      },
    },
    infoText: {
      display: 'none',
      textAlign: 'left',
      position: 'absolute',
      bottom: 22,
      zIndex: 5,
      width: 'max-content',
      maxWidth: 200,
      backgroundColor: 'white',
      color: theme.palette.common.black,
      fontSize: 10,
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 4,
      padding: theme.spacing(1),
      '&:after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 8,
        height: 8,
        bottom: -5,
        left: 'calc(50% - 4px)',
        backgroundColor: 'white',
        borderRight: `1px solid ${theme.palette.secondary.main}`,
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        transform: 'rotate(45deg)',
      },
      '&$infoTextService': {
        right: -6,
        '&:after': {
          left: 'calc(100% - 16px)',
        },
      },
    },
    infoTextService: {},
    accordionDetails: {
      padding: 0,
      flexDirection: 'column',
    },
    title: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.palette.background.paper,
      padding: theme.spacing(1.5, 0),
    },
    valid: {
      color: theme.palette.success.main,
    },
    validWithConfirm: {
      color: theme.palette.warning.main,
    },
    noValid: {
      color: theme.palette.error.main,
    },
  }),
);

export const ScoringModelTable = () => {
  const classes = useStyles();
  const location = useLocation();
  const row = location.state as ScoringApprovalViewModel;

  const rows = row.result.rules?.map((rule: ScoringRuleResultViewModel) => {
    const classById = rule.code.startsWith('A')
      ? classes.coloredBackA
      : rule.code.startsWith('X')
        ? classes.coloredBackX
        : rule.code.startsWith('N')
          ? classes.coloredBackN
          : '';
    const coloredBackClass = rule.value === 1 ? classById : '';

    return (
      <TableRow className={classes.row} key={rule.code}>
        <TableCell size={'medium'} className={coloredBackClass}>
          {rule.code}
        </TableCell>
        <TableCell size={'medium'} className={coloredBackClass}>
          {rule.name}
          <div className={classes.infoIcon}>
            <div>i</div>
            <div className={classes.infoText}>{rule.description.descriptionDisplayMessage}</div>
          </div>
        </TableCell>
        <TableCell size={'medium'} className={coloredBackClass}>
          {rule.description.expectedDisplayMessage}
        </TableCell>
        <TableCell size={'medium'} className={coloredBackClass}>
          {rule.description.actualDisplayMessage}
        </TableCell>
        <TableCell size={'medium'} align="right" className={clsx(coloredBackClass)}>
          <Role role={ApplicationRole.Admin}>
            {rule.description.serviceMessages && (
              <div className={classes.infoIcon}>
                <div>i</div>
                <div className={clsx(classes.infoText, classes.infoTextService)}>
                  {rule.description.serviceMessages.map((message) => (
                    <>
                      {message}
                      <br />
                    </>
                  ))}
                </div>
              </div>
            )}
          </Role>
          {rule.value}
        </TableCell>
      </TableRow>
    );
  });
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <Breadcrumbs
        link={`/quotas/calculator/${id}`}
        tab="Одобрение"
        text={`Одобрение | ${row.name}`}
      />
      <Accordion expanded={true} key={row.name}>
        <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
          <div className={classes.title}>
            <Typography variant="subtitle1">{row.name}</Typography>
            <ScoringModelStatus isValid={row.result.isValid} message={row.result.message} />
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Table>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell size={'medium'}>ID</TableCell>
                <TableCell size={'medium'}>Название</TableCell>
                <TableCell size={'medium'}>Порог</TableCell>
                <TableCell size={'medium'}>Фактическое</TableCell>
                <TableCell size={'medium'} align="right">
                  Результат
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

type ScoringModelStatusProps = Omit<ScoringModelResult, 'rules' | 'file'>;

const ScoringModelStatus = (props: ScoringModelStatusProps) => {
  const classes = useStyles();
  const { isValid, message } = props;
  const validClass = !isValid ? classes.noValid : classes.valid;

  return (
    <Typography variant="subtitle1" component="div" className={validClass}>
      {message}
    </Typography>
  );
};
