import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import {
  CommentStatus,
  MissingDebtStatus,
  MissingImportDates,
  MissingViewModel,
} from 'schema/serverTypes';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { IconSprite } from '../icons';
import { themeOrange as theme } from 'theme';
import { TableSortLabel } from '../data-grid/TableSortLabel';
import { MissingSortBy } from 'schema/serverTypes';
import { Colorize, formatCurrency } from 'components/utils';
import { User } from '../users';

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      backgroundColor: theme.palette.common.white,
      fontSize: 12,
      boxShadow: '0px 0px 20px 0px rgb(56 60 97 / 10%)',
      color: theme.palette.common.black,
      display: 'grid',
      gridTemplateColumns: '20fr 15fr 33fr 20fr 110px 38px',
    },
    th: {
      fontSize: 12,
      color: theme.palette.text.secondary,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 16,
      paddingBottom: 16,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    rowWrapper: {
      display: 'contents',
      backgroundColor: 'green',
      '&:hover > div': {
        backgroundColor: theme.palette.background.light,
      },
      '& > div': {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottom: `2px solid ${theme.palette.background.default}`,
      },
    },
    lesseeName: {
      paddingLeft: 20,
      paddingRight: 12,
      '& > a': {
        color: theme.palette.common.black,
        '&:hover': {
          textDecoration: 'underline',
          color: theme.palette.secondary.dark,
        },
      },
    },
    managerName: {
      color: theme.palette.text.secondary,
    },
    contractStatus: {
      color: theme.palette.text.secondary,
      fontSize: '11px',
    },
    values: {
      gridColumn: '3/7',
      display: 'grid',
      gridColumnGap: 12,
      gridRowGap: 8,
      gridTemplateColumns: '33fr 20fr 100px 30px',
    },
    debt: {
      textAlign: 'center',
    },
    comments: {
      paddingRight: 20,
      position: 'relative',
      '&:after': {
        content: '""',
        pointerEvents: 'none',
        display: 'block',
        position: 'absolute',
        left: 6,
        top: 0,
        borderRadius: '50%',
        backgroundColor: 'transparent',
        width: 6,
        height: 6,
      },
      '&$notCommented:after': {
        backgroundColor: theme.palette.error.main,
      },
      '&$commented:after': {
        backgroundColor: theme.palette.green.main,
      },
    },
    notCommented: {},
    commented: {},
    received: {},
    updated: {
      textAlign: 'right',
      fontSize: 10,
      color: theme.palette.text.secondary,
      margin: theme.spacing(2, 0, 0),
    },
    ok: {
      color: theme.palette.green.main,
    },
    right: {
      textAlign: 'right',
    },
    star: {
      marginLeft: -23,
      marginRight: 10,
    },
  })
);

type MissingTableProps = {
  rows: MissingViewModel[];
  isLoading: boolean;
  importDates?: MissingImportDates;
  sorting: any;
};

export const MissingTable = (props: MissingTableProps) => {
  const { rows, sorting, importDates = {} } = props;
  const { missings, overdues } = importDates;
  const { t } = useTranslation();
  const classes = useStyles();

  const groupByKey = (list: any, key: string) =>
    list.reduce(
      (hash: any, obj: any) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    );

  const rowsByContractNumber = Object.entries(groupByKey(rows, 'contractNumber'));

  return (
    <>
      <div className={classes.table}>
        <div className={clsx(classes.th, classes.lesseeName)}>{t('Counterparty / Contract')}</div>
        <div className={clsx(classes.th, classes.right)}>
          <TableSortLabel columnName={MissingSortBy.DebtDepth} sorting={sorting}>
            {t('Overdue')}
          </TableSortLabel>
        </div>
        <div className={clsx(classes.th)}>{t('Document type')}</div>
        <div className={clsx(classes.th)}>
          <TableSortLabel columnName={MissingSortBy.DeliveryStatus} sorting={sorting}>
            {t('Status')}
          </TableSortLabel>
        </div>
        <div className={clsx(classes.th, classes.debt)}>
          <TableSortLabel columnName={MissingSortBy.PlannedDeliveryDate} sorting={sorting}>
            {t('Debt (day)')}
          </TableSortLabel>
        </div>
        <div className={classes.th} />
        {rowsByContractNumber.map((row: any) => {
          return <TableItem key={row[0]} values={row[1]} />;
        })}
      </div>
      <div className={classes.updated}>
        {missings && (
          <>
            {t('Missings updated')} {dayjs(missings).format('DD.MM.YYYY')}
          </>
        )}
        {overdues && (
          <>
            {' '}
            | {t('Overdue payments updated')} {dayjs(overdues).format('DD.MM.YYYY')}
          </>
        )}
      </div>
    </>
  );
};

const TableItem = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    lesseeInn,
    lesseeName,
    manager: { name, isActive },
    contractNumber,
    contractStatus,
    debtDepth,
    overdueAmount,
    currency,
    dealerName,
  } = props.values[0];
  const { pathname } = useLocation();

  return (
    <div className={classes.rowWrapper}>
      <div className={classes.lesseeName}>
        <Link to={`/counterparties/${lesseeInn}#missing`} target="_blank" rel="noopener">
          {lesseeName}
        </Link>
        <br />
        <br />
        {contractNumber}
        <br />
        {contractStatus && contractStatus !== '' && (
          <>
            <br />
            <Typography component="span" variant={'body2'} className={classes.contractStatus}>
              {contractStatus}
            </Typography>
          </>
        )}
        <br />
        <br />
        <Typography component="span" variant={'body2'} className={classes.managerName}>
          <User name={name} isActive={isActive} />
        </Typography>
      </div>
      <div>
        {overdueAmount ? (
          <>
            <Colorize
              limits={[0, 0]}
              value={overdueAmount}
              isFormatMoney
              unit={formatCurrency(currency)}
            />
            <br />
            <br />
          </>
        ) : (
          <>
            {t('Empty')}
            <br />
            <br />
          </>
        )}
        {debtDepth ? (
          <>
            <Colorize value={debtDepth} unit={t('day')} />
            <br />
            <br />
          </>
        ) : (
          <>
            —<br />
            <br />
          </>
        )}
        <Link
          to={`${pathname}/overdue/comments/?dealerName=${dealerName}&contractNumber=${contractNumber}`}
        >
          <IconSprite icon="chat" width="10px" color={theme.palette.primary.main} />
        </Link>
      </div>
      <div className={classes.values}>
        {props.values.map((row: any) => {
          return <Document key={row.id} row={row} />;
        })}
      </div>
    </div>
  );
};

const Document = (props: any) => {
  const classes = useStyles();
  const {
    documentType,
    status,
    debtDays,
    id,
    commentStatus: commentStatus1,
    missingDebtStatus,
    hasDeliveredStatus,
  } = props.row;
  const { pathname, search } = useLocation();

  const missingStatus = commentStatus1 as CommentStatus;
  const statusClassName =
    missingStatus === CommentStatus.HasComments
      ? classes.commented
      : missingStatus === CommentStatus.NoComments
      ? classes.notCommented
      : classes.received;

  return (
    <>
      <div>{documentType}</div>
      <div>
        {hasDeliveredStatus ? (
          <>
            <IconSprite
              width="10px"
              color={theme.palette.attention.main}
              icon="star"
              className={classes.star}
            />
            {status}
          </>
        ) : (
          status
        )}
      </div>
      <div className={classes.debt}>
        {missingDebtStatus === MissingDebtStatus.Ok ? (
          <span className={classes.ok}>OK</span>
        ) : missingDebtStatus === MissingDebtStatus.None ? (
          '—'
        ) : (
          <Colorize value={debtDays} />
        )}
      </div>
      <div className={clsx(classes.comments, statusClassName)}>
        <Link to={`${pathname}/comments/${id}${search}`}>
          <IconSprite icon="chat" width="10px" color={theme.palette.primary.main} />
        </Link>
      </div>
    </>
  );
};
