import MenuItem from '@material-ui/core/MenuItem';
import { Select } from 'components/form/Select';
import { useCallback } from 'react';
import { Control, FieldPath, UseFormSetValue } from 'react-hook-form';
import { ShipmentItemHead } from 'schema/serverTypes';
import { ShipmentItemFormValues } from './types';
import { useTranslation } from 'react-i18next';

export type CounterpartySelectProps = {
  control: Control<ShipmentItemFormValues, any>;
  setValue: UseFormSetValue<ShipmentItemFormValues>;
  label: string;
  name: FieldPath<ShipmentItemFormValues>;
  disabled?: boolean;
  heads?: ShipmentItemHead[];
};

export const CounterpartySelect = (props: CounterpartySelectProps) => {
  const { label, name, control, disabled = false, heads = [], setValue } = props;
  const { t } = useTranslation();

  const handleOnChange = useCallback(
    (value?: any) => {
      if (value === undefined) {
        return;
      }
      const id = value as number;
      let head = heads.find((t) => t.id === id);

      if (head !== undefined) {
        if (name === 'lesseeContact.id') {
          setValue('lesseeContact.firstName', head.firstName ?? '');
          setValue('lesseeContact.lastName', head.lastName ?? '');
          setValue('lesseeContact.middleName', head.middleName ?? '');
        } else if (name === 'lessorContact.id') {
          setValue('lessorContact.firstName', head.firstName ?? '');
          setValue('lessorContact.lastName', head.lastName ?? '');
          setValue('lessorContact.middleName', head.middleName ?? '');
        } else if (name === 'dealerContact.id') {
          setValue('dealerContact.firstName', head.firstName ?? '');
          setValue('dealerContact.lastName', head.lastName ?? '');
          setValue('dealerContact.middleName', head.middleName ?? '');
        }
      }
    },
    [name, heads, setValue]
  );

  return (
    <Select
      label={label}
      name={name}
      control={control}
      disabled={disabled}
      onChangeCallback={handleOnChange}
      rules={{
        required: {
          value: true,
          message: t('Required'),
        },
      }}
    >
      {heads.map((head) => {
        return (
          <MenuItem key={head.id} value={head.id}>
            {head.lastName} {head.firstName} {head.middleName}
          </MenuItem>
        );
      })}
    </Select>
  );
};
