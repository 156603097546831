import { useTranslation } from 'react-i18next';
import { Button, Grid, IconSprite, Role } from 'components';
import {
  Card,
  CardActions,
  CardContent,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { themeOrange as theme } from 'theme';
import { ApplicationRole, TemplateViewModel } from 'schema';
import { Control, FieldValues } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useBackendFetch, useTemplatesMutation } from '../../services';
import { calculationUrl } from 'services/urls';
import { ModalForm, useModalForm } from 'components';
import { SwitchWithoutForm } from '../form/SwitchWithoutForm';
import { useQueryClient } from 'react-query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'block',
      height: '100%',
      textDecoration: 'none',
    },
    card: {
      height: '100%',
      display: 'flex',
      borderRadius: 0,
    },
    content: {
      padding: theme.spacing(1.5, 0, 2.5, 0),
      width: '100%',
    },
    icon: {
      width: 28,
      minWidth: 28,
      height: 28,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.light,
      margin: theme.spacing(1.25, 1.5, 1.25, 1.25),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      color: theme.palette.secondary.dark,
      marginBottom: theme.spacing(1.5),
      fontWeight: 400,
      fontSize: 14,
    },
    description: {
      color: theme.palette.text.primary,
      fontSize: 12,
    },
    action: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      '&.MuiCardActions-spacing > :not(:first-child)': {
        marginLeft: 0,
        marginRight: '-2px',
      },
    },
    actionButton: {
      padding: '0 !important',
    },
    menuItem: {
      padding: 0,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    menuIcon: {
      minWidth: 23,
    },
    menuLink: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      padding: '3px 9px',
      marginBottom: 4,
      color: theme.palette.common.black,
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

type TemplateCardProps = {
  template: TemplateViewModel;
  control: Control<FieldValues, Object>;
};

export const TemplateCard = (props: TemplateCardProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { template } = props;
  const { onOpen, onClose, open: isModalOpen } = useModalForm();
  const [isDeleted, setDeleted] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const fetchBackend = useBackendFetch();

  const getFile = useCallback(
    async (ev) => {
      ev.stopPropagation();
      const response = await fetchBackend(`${calculationUrl}${template.downloadUrl}`);
      const result = await response.blob();
      const blob = new Blob([result], {
        type: result.type,
      });
      const newUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = newUrl;
      a.download = template.fileName;
      document.body.appendChild(a);
      a.click();
      setAnchorEl(null);
    },
    [template.downloadUrl, template.fileName, fetchBackend]
  );

  const { mutateAsync: mutateAsyncDelete } = useTemplatesMutation(`${template.id}`, '', {
    method: 'DELETE',
    onSuccess: () => {
      setDeleted(true);
    },
  });

  const handleDelete = useCallback(() => {
    onOpen();
    setAnchorEl(null);
  }, [onOpen, setAnchorEl]);

  const handleConfirmDelete = useCallback(() => {
    mutateAsyncDelete(null);
    onClose();
  }, [onClose, mutateAsyncDelete]);

  const queryClient = useQueryClient();

  const { mutateAsync: mutateAsyncToggleSwitch } = useTemplatesMutation(
    `${template.id}`,
    '/availability',
    {
      method: 'PUT',
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return Array.isArray(query.queryKey) && query.queryKey.indexOf('templates') >= 0;
          },
        });
      },
    }
  );

  const handleToggleSwitch = useCallback(() => {
    mutateAsyncToggleSwitch({ disabled: !template.disabled });
  }, [mutateAsyncToggleSwitch, template.disabled]);

  if (isDeleted) return null;

  return (
    <>
      <Grid item xl={6} lg={8} md={12} xs={24} key={template.id}>
        <Card className={classes.card}>
          <Link to={`/templates/${template.id}`} className={classes.icon}>
            <IconSprite
              icon="template"
              width="12px"
              height="16px"
              color={theme.palette.secondary.dark}
            />
          </Link>
          <CardContent className={classes.content}>
            <Link to={`/templates/${template.id}`}>
              <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                {template.title}
              </Typography>
              <Typography className={classes.description} variant={'body2'} component={'p'}>
                {template.description}
              </Typography>
            </Link>
          </CardContent>
          <CardActions className={classes.action}>
            <Button
              onClick={handleClick}
              variant="iconButton"
              className={classes.actionButton}
              endIcon={
                <IconSprite icon="vertical-menu" width="24px" color={theme.palette.text.primary} />
              }
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              onClose={handleClose}
            >
              <Role
                role={[
                  ApplicationRole.Admin,
                  ApplicationRole.SuperSalesManager,
                  ApplicationRole.RiskManager,
                ]}
              >
                <MenuItem
                  classes={{
                    root: classes.menuItem,
                  }}
                >
                  <Link to={`/templates/${template.id}`} className={classes.menuLink}>
                    <ListItemIcon
                      classes={{
                        root: classes.menuIcon,
                      }}
                    >
                      <IconSprite
                        icon="edit"
                        width="14px"
                        height="15px"
                        color={theme.palette.text.primary}
                        hoverColor={theme.palette.primary.main}
                      />
                    </ListItemIcon>
                    {t('Edit')}
                  </Link>
                </MenuItem>
              </Role>
              <MenuItem
                classes={{
                  root: classes.menuItem,
                }}
                onClick={getFile}
              >
                <div className={classes.menuLink}>
                  <ListItemIcon
                    classes={{
                      root: classes.menuIcon,
                    }}
                  >
                    <IconSprite
                      icon="download"
                      width="14px"
                      height="15px"
                      color={theme.palette.text.primary}
                      hoverColor={theme.palette.primary.main}
                    />
                  </ListItemIcon>
                  {t('Download')}
                </div>
              </MenuItem>
              <Role
                role={[
                  ApplicationRole.Admin,
                  ApplicationRole.SuperSalesManager,
                  ApplicationRole.RiskManager,
                ]}
              >
                <MenuItem
                  classes={{
                    root: classes.menuItem,
                  }}
                  onClick={handleDelete}
                >
                  <div className={classes.menuLink}>
                    <ListItemIcon
                      classes={{
                        root: classes.menuIcon,
                      }}
                    >
                      <IconSprite
                        icon="delete"
                        width="14px"
                        height="15px"
                        color={theme.palette.text.primary}
                        hoverColor={theme.palette.primary.main}
                      />
                    </ListItemIcon>
                    {t('Delete')}
                  </div>
                </MenuItem>
              </Role>
            </Menu>
            <Role
              role={[
                ApplicationRole.Admin,
                ApplicationRole.SuperSalesManager,
                ApplicationRole.RiskManager,
              ]}
            >
              <SwitchWithoutForm toggleSwitch={handleToggleSwitch} disabled={template.disabled} />
            </Role>
          </CardActions>
        </Card>
      </Grid>

      <ModalForm open={isModalOpen} onClose={onClose}>
        <div className={classes.modalRoot}>
          <Typography variant={'h2'} className={classes.modalTitle}>
            {t('Delete Template')}
          </Typography>
          <Typography variant="subtitle2" className={classes.modalText}>
            {t('Are you sure you want to delete the template')}
          </Typography>
          <Typography variant="body2" className={classes.modalText}>
            {template.title}?
          </Typography>
          <div>
            <Button
              variant="contained"
              className={classes.modalButton}
              onClick={handleConfirmDelete}
            >
              {t('Yes')}
            </Button>
            <Button variant="outlined" onClick={onClose} className={classes.modalButton}>
              {t('No')}
            </Button>
          </div>
        </div>
      </ModalForm>
    </>
  );
};
