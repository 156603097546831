import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Portal,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { CurrencyRatesInfo } from 'components/calculator/CalculationForm/CurrencyRatesInfo';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'components/utils/formatNumber';
import { formatCurrency } from 'components/utils/formatCurrency';
import {
  CalculationMethod,
  Currency,
  QuotaNomenclatureViewModel,
  TemplateKind,
} from 'schema/serverTypes';
import { CalculationResults } from 'components/calculator/CalculationForm/results/CalculationResults';
import { SeasonalPaymentSchedule } from 'components/calculator/CalculationForm/results/SeasonalPaymentSchedule';
import { StraightLinePaymentSchedule } from 'components/calculator/CalculationForm/results/StraightLinePaymentSchedule';
import { AnnuityPaymentSchedule } from 'components/calculator/CalculationForm/results/AnnuityPaymentSchedule';
import { Button, ModalForm, Role, useModalForm, useToast, WarningTooltip } from 'components';
import { ApplicationRoles } from 'services/authentication/ApplicationRoles';
import { SaleContractFields } from './SaleContractFields';
import { CalculationFormProps } from './types';
import { FormProvider } from 'react-hook-form';
import { LeaseContractFields } from './LeaseContractFields';
import { ComponentsFields } from './ComponentsFields';
import { PaymentScheduleAccordion } from './PaymentScheduleAccordion';
import { useQueryClient } from 'react-query';
import { useCreateScoringIssueMutation, useUserRole } from '../../../services';
import { PrintableTemplateList } from '../../templates/PrintableTemplatesList';
import { NomenclaturesRequestForm } from '../../calculator/CalculationForm/NomenclaturesRequestForm';
import { LeasingProductBrandsContextProvider } from './LeasingProductBrandsContextProvider';
import { Link } from 'react-router-dom';
import { themeOrange as theme } from '../../../theme';
import { useLeasingProductByNomenclature } from './useLeasingProductByNomenclature';
import { ApprovalRequestForm } from './ApprovalRequestForm';
import { DataForQualificationsEditPanel as QualificationsEditPanel } from '../Qualification';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panels: {
      marginBottom: (props: CalculationFormProps) => (props.isTask ? '0' : '75px'),
    },
    actions: {
      [theme.breakpoints.down(1200)]: {
        width: 'calc(100% - 85px)',
        left: 63,
      },
      display: 'flex',
      justifyContent: 'flex-start',
      width: 'calc(100% - 238px)',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      left: 216,
      position: 'fixed',
      zIndex: 1,
    },
    actionsTask: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      position: 'static',
      zIndex: 1,
      marginBottom: theme.spacing(2),
    },
    actionButton: {
      marginRight: theme.spacing(2.5),
    },
    actionButtonRight: {
      marginLeft: 'auto',
      marginRight: 0,
    },
    warningIcon: {
      cursor: 'pointer',
      '&$warningIcon svg': {
        fill: theme.palette.darkAttention.main,
        cursor: 'pointer',
        pointerEvents: 'auto',
      },
    },
    link: {
      color: theme.palette.primary.main,
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export const CalculationForm = forwardRef<HTMLDivElement, CalculationFormProps>((props, ref) => {
  const classes = useStyles(props);
  const toast = useToast();
  const { t } = useTranslation();
  const { isSalesSupport, isInsideSales, isAdmin, isSalesManager, isSuperSalesManager } =
    useUserRole();

  const {
    calculationResult,
    isLoading,
    isTask = false,
    isLocked = false,
    isContractExecutionNotified = false,
    quotaId,
    issueId,
    form,
    onSubmit,
    sendToIsEnabled,
    scoringApprovalIssueId,
    //approvalEnabled = false,
  } = props;

  const approvalEnabled = false;

  //DLLCRMV2-1481
  const [calculationMethodChange, setCalculationMethodChange] = useState<number>(0);

  const isCopyEnabled = quotaId !== undefined;

  const { setValue, control, watch } = form;

  const { leasingProductByNomenclature, vendor } = useLeasingProductByNomenclature(control);

  const {
    onOpen: onOpenNomenclatures,
    onClose: onCloseNomenclatures,
    open: openNomenclatures,
  } = useModalForm();

  const {
    onOpen: onOpenApprovalRequest,
    onClose: onCloseApprovalRequest,
    open: openApprovalRequest,
  } = useModalForm();

  const {
    onOpen: onOpenApprovalWarning,
    onClose: onCloseApprovalWarning,
    open: openApprovalWarning,
  } = useModalForm();

  const {
    onOpen: onOpenLeasingProductWarning,
    onClose: onCloseLeasingProductWarning,
    open: openLeasingProductWarning,
  } = useModalForm();

  const [isOpenQualificationsPanel, setOpenQualificationsPanel] = useState<boolean>(false);

  const handleOnCalculate = useCallback(
    (event) => {
      setValue('save', false, { shouldValidate: true });
      onSubmit(event);
    },
    [setValue, onSubmit]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleOnCalculate(event);
      }
    },
    [handleOnCalculate]
  );

  const handleOnSave = useCallback(
    (event) => {
      setValue('save', true, { shouldValidate: true });
      const leaseProduct = watch('leaseProduct');
      if (
        vendor !== '' &&
        leasingProductByNomenclature?.name &&
        leasingProductByNomenclature?.name !== leaseProduct
      ) {
        onOpenLeasingProductWarning();
      } else {
        onSubmit(event);
        onCloseApprovalWarning();
      }
    },
    [
      setValue,
      onSubmit,
      leasingProductByNomenclature?.name,
      vendor,
      onOpenLeasingProductWarning,
      watch,
      onCloseApprovalWarning,
    ]
  );

  const handleChangeLeaseProduct = useCallback(
    (event, changed: boolean) => {
      if (changed) {
        setValue('leaseProduct', leasingProductByNomenclature?.name, { shouldValidate: true });
        onSubmit(event);
      } else {
        onSubmit(event);
      }
      onCloseLeasingProductWarning();
    },
    [onSubmit, setValue, onCloseLeasingProductWarning, leasingProductByNomenclature?.name]
  );

  const handleOnCopy = useCallback(
    (event) => {
      if (isCopyEnabled) {
        setValue('copy', true, { shouldValidate: true });
        onSubmit(event);
      }
    },
    [isCopyEnabled, setValue, onSubmit]
  );

  const newNomenclatures = useMemo(() => {
    return props.nomenclatures
      ? props.nomenclatures?.filter((item: QuotaNomenclatureViewModel) => !item?.code)
      : [];
  }, [props.nomenclatures]);

  const queryClient = useQueryClient();

  const { mutateAsync } = useCreateScoringIssueMutation(quotaId ?? 0, {
    onSuccess: (result) => {
      toast(
        <>
          Создана задача №
          <Link
            to={`/tasks/${result.id}`}
            style={{ color: theme.palette.primary.main }}
            className={classes.link}
          >
            {result.id}
          </Link>
        </>,
        'success'
      );
      queryClient.invalidateQueries({
        predicate: (query) => {
          if (Array.isArray(query.queryKey)) {
            const key = query.queryKey as Array<string>;
            if (key[0] === 'quotas') {
              return true;
            }
          }
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string)?.indexOf('quotas') > -1
          );
        },
      });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  return (
    <FormProvider {...form}>
      <LeasingProductBrandsContextProvider>
        <form onSubmit={onSubmit} onKeyDown={handleKeyDown}>
          <div className={classes.panels}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="subtitle1">{t('ContractOfSale')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SaleContractFields
                  {...props}
                  quotaId={quotaId}
                  onOpenNomenclatures={onOpenNomenclatures}
                />
              </AccordionDetails>
            </Accordion>
            <CurrencyRatesInfo />
            <Accordion defaultExpanded={true}>
              <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                <Typography variant="subtitle1">{t('ContractOfLease')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <LeaseContractFields
                  totalMargin={calculationResult?.totalMargin}
                  setCalculationMethodChange={setCalculationMethodChange}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="subtitle1">{t('Components')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ComponentsFields />
              </AccordionDetails>
            </Accordion>
            <PaymentScheduleAccordion
              quotaId={quotaId}
              calculationMethodChange={calculationMethodChange}
            />
            <Accordion disabled defaultExpanded={false} expanded={!!calculationResult}>
              <AccordionSummary ref={ref} aria-controls="panel4a-content" id="panel4a-header">
                <Typography variant="subtitle1">{t('CalculationResult')}</Typography>
                <Typography color="secondary" variant="body1">
                  &nbsp; NBV &nbsp;
                </Typography>
                <Typography color="secondary" variant="subtitle1">
                  {calculationResult?.fundingAmountNBV &&
                    formatNumber(calculationResult?.fundingAmountNBV, 2, true)}
                  &nbsp;
                  {calculationResult?.fundingAmountNBV && formatCurrency(Currency.Ruble)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {calculationResult && <CalculationResults data={calculationResult} />}
              </AccordionDetails>
            </Accordion>
            <Accordion disabled defaultExpanded={false} expanded={!!calculationResult}>
              <AccordionSummary aria-controls="panel5a-content" id="panel5a-header">
                <Typography variant="subtitle1">{t('ScheduleOfPayments')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {calculationResult?.calculationMethod === CalculationMethod.Annuity && (
                  <AnnuityPaymentSchedule {...calculationResult} />
                )}
                {calculationResult?.calculationMethod === CalculationMethod.StraightLine && (
                  <StraightLinePaymentSchedule {...calculationResult} />
                )}
                {calculationResult?.calculationMethod === CalculationMethod.Seasonal && (
                  <SeasonalPaymentSchedule {...calculationResult} />
                )}
                {calculationResult?.calculationMethod === CalculationMethod.SuperSeasonal && (
                  <SeasonalPaymentSchedule {...calculationResult} />
                )}
              </AccordionDetails>
            </Accordion>
            {quotaId && <PrintableTemplateList quotaId={quotaId} kind={TemplateKind.Calculation} />}
          </div>
          {isTask ? (
            <Paper square className={classes.actionsTask}>
              <div className={classes.actionButton}>
                <Button
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  onClick={handleOnCalculate}
                >
                  {t('Calculate')}
                </Button>
              </div>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <div className={classes.actionButton}>
                  <Button
                    color="primary"
                    size="medium"
                    type="button"
                    variant="outlined"
                    disabled={isLoading || isLocked}
                    onClick={scoringApprovalIssueId ? onOpenApprovalWarning : handleOnSave}
                  >
                    {t('Save')}
                  </Button>
                </div>
                {isCopyEnabled && (
                  <div className={classes.actionButton}>
                    <Button
                      color="primary"
                      size="medium"
                      type="button"
                      variant="outlined"
                      disabled={isLoading}
                      onClick={handleOnCopy}
                    >
                      {t('SaveAsNew')}
                    </Button>
                  </div>
                )}
                {quotaId && sendToIsEnabled && (
                  <div className={classes.actionButton}>
                    <Button
                      color="primary"
                      size="medium"
                      type="button"
                      variant="outlined"
                      disabled={isLoading || isContractExecutionNotified}
                      onClick={() => mutateAsync(quotaId, {})}
                    >
                      {t('Transfer to IS')}
                    </Button>
                  </div>
                )}
              </Role>
              {quotaId &&
                ((isSalesSupport && issueId) ||
                  isInsideSales ||
                  isAdmin ||
                  isSalesManager ||
                  isSuperSalesManager) && (
                  <div className={clsx(classes.actionButton, classes.actionButtonRight)}>
                    <Button
                      color="primary"
                      size="medium"
                      type="button"
                      variant="outlined"
                      onClick={() => setOpenQualificationsPanel(true)}
                      disabled={isLoading}
                    >
                      {t('Qualify a deal')}
                    </Button>
                  </div>
                )}
            </Paper>
          ) : (
            <Portal container={document.body}>
              <Paper square className={classes.actions}>
                <div className={classes.actionButton}>
                  <Button
                    color="primary"
                    size="medium"
                    type="submit"
                    variant="contained"
                    disabled={isLoading}
                    onClick={handleOnCalculate}
                  >
                    {t('Calculate')}
                  </Button>
                </div>
                <Role role={ApplicationRoles.allExceptAssetManager}>
                  <div className={classes.actionButton}>
                    <Button
                      color="primary"
                      size="medium"
                      type="button"
                      variant="outlined"
                      disabled={isLoading || isLocked}
                      onClick={scoringApprovalIssueId ? onOpenApprovalWarning : handleOnSave}
                    >
                      {t('Save')}
                    </Button>
                  </div>
                  {isCopyEnabled && (
                    <div className={classes.actionButton}>
                      <Button
                        color="primary"
                        size="medium"
                        type="button"
                        variant="outlined"
                        disabled={isLoading}
                        onClick={handleOnCopy}
                      >
                        {t('SaveAsNew')}
                      </Button>
                    </div>
                  )}
                  {quotaId && sendToIsEnabled && (
                    <div className={classes.actionButton}>
                      <Button
                        color="primary"
                        size="medium"
                        type="button"
                        variant="outlined"
                        disabled={isLoading || isContractExecutionNotified}
                        onClick={() => mutateAsync(quotaId, {})}
                      >
                        {t('Transfer to IS')}
                      </Button>
                    </div>
                  )}
                  {quotaId && approvalEnabled && (
                    <div className={classes.actionButton}>
                      <Button
                        color="primary"
                        size="medium"
                        type="button"
                        variant="outlined"
                        onClick={onOpenApprovalRequest}
                        disabled={isLoading}
                        className={newNomenclatures.length > 0 ? 'Mui-disabled' : ''}
                        startIcon={
                          newNomenclatures.length > 0 && (
                            <WarningTooltip
                              arrow
                              placement="bottom-start"
                              className={classes.warningIcon}
                              onOpenNomenclatures={onOpenNomenclatures}
                              taskIds={
                                newNomenclatures.filter((item: any) => !item.taskId).length > 0
                                  ? []
                                  : newNomenclatures
                                      .filter((item: any) => item.taskId)
                                      .map((item: any) => item.taskId)
                              }
                            />
                          )
                        }
                      >
                        {t('Одобрить')}
                      </Button>
                    </div>
                  )}
                </Role>
                {quotaId &&
                  ((isSalesSupport && issueId) ||
                    isInsideSales ||
                    isAdmin ||
                    isSalesManager ||
                    isSuperSalesManager) && (
                    <div className={clsx(classes.actionButton, classes.actionButtonRight)}>
                      <Button
                        color="primary"
                        size="medium"
                        type="button"
                        variant="outlined"
                        onClick={() => setOpenQualificationsPanel(true)}
                        disabled={isLoading}
                      >
                        {t('Qualify a deal')}
                      </Button>
                    </div>
                  )}
              </Paper>
            </Portal>
          )}
        </form>
        {isOpenQualificationsPanel && (
          <QualificationsEditPanel
            quotaId={quotaId}
            onClose={() => setOpenQualificationsPanel(false)}
          />
        )}
        {quotaId && newNomenclatures.length > 0 && (
          <ModalForm open={openNomenclatures} onClose={onCloseNomenclatures}>
            <NomenclaturesRequestForm
              newNomenclatures={newNomenclatures.filter((item: any) => !item.taskId)}
              quotaId={quotaId}
              onClose={onCloseNomenclatures}
            />
          </ModalForm>
        )}
        <ModalForm open={openLeasingProductWarning} onClose={onCloseLeasingProductWarning}>
          <div className={classes.modalRoot}>
            <Typography variant={'h2'} className={classes.modalTitle}>
              {t('Warning')}
            </Typography>
            <Typography variant="body2" className={classes.modalText}>
              {`Указанный бренд ${vendor} соотвествует каналу продаж ${leasingProductByNomenclature?.name}, изменить?`}
            </Typography>
            <div>
              <Button
                variant="contained"
                className={classes.modalButton}
                onClick={(event) => handleChangeLeaseProduct(event, true)}
              >
                {t('Yes')}
              </Button>
              <Button
                variant="outlined"
                className={classes.modalButton}
                onClick={(event) => handleChangeLeaseProduct(event, false)}
              >
                {t('No')}
              </Button>
            </div>
          </div>
        </ModalForm>
        {quotaId && (
          <ModalForm open={openApprovalRequest} onClose={onCloseApprovalRequest}>
            <ApprovalRequestForm quotaId={quotaId} onClose={onCloseApprovalRequest} />
          </ModalForm>
        )}
        {quotaId && (
          <ModalForm open={openApprovalWarning} onClose={onCloseApprovalWarning}>
            <div className={classes.modalRoot}>
              <Typography variant={'h2'} className={classes.modalTitle}>
                {t('Warning')}
              </Typography>
              <Typography variant="body2" className={classes.modalText}>
                {`Изменения расчета приведёт к отмене действующего ручного одобрения`}
              </Typography>
              <div>
                <Button variant="contained" className={classes.modalButton} onClick={handleOnSave}>
                  Всё равно сохранить
                </Button>
              </div>
            </div>
          </ModalForm>
        )}
      </LeasingProductBrandsContextProvider>
    </FormProvider>
  );
});
