import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import { Tabs, useTabs } from 'components/Tabs';
import SwipeableViews from 'react-swipeable-views';
import { Empty } from '../../Empty';
import { useCurrentUserTabsQuery } from 'services/api';
import { InsideSalesTab } from './InsideSalesTab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1.5, 2.5),
      minHeight: 300,
      display: 'grid',
      gridTemplateRows: '30px 1fr',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      paddingRight: theme.spacing(5),
    },
    tabs: {
      marginBottom: -2,
    },
    head: {
      fontSize: 10,
      fontWeight: 400,
      padding: 6,
      '&.MuiTableCell-root:first-child': {
        padding: 6,
      },
      '&.MuiTableCell-root:last-child': {
        padding: 6,
      },
    },
    name: {
      fontWeight: 600,
      fontSize: 12,
      padding: theme.spacing(0.75),
      marginRight: theme.spacing(2),
      '&': {
        paddingLeft: 6,
      },
    },
  })
);

export const InsideSalesContainer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data = [], isLoading } = useCurrentUserTabsQuery();

  const tabs = useMemo(() => {
    return data.map((group) => {
      return group.name.length >= 15 ? `${group.name.substr(0, 11)}...` : group.name;
    });
  }, [data]);

  const tabsProps = useTabs(tabs);
  const { tabIndex, onChangeTabIndex, onChangeTab } = tabsProps;

  return (
    <Paper className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="subtitle1">Inside Sales Progress</Typography>
        {tabs.length !== 0 && (
          <Tabs
            {...tabsProps}
            value={tabIndex}
            onChangeTab={onChangeTab}
            className={classes.tabs}
          />
        )}
      </Box>
      {isLoading ? (
        <Empty>{t('Please wait while the data is loading.')}</Empty>
      ) : (
        <>
          <SwipeableViews
            containerStyle={{
              transition: 'transform 0.6s ease-out 0s',
              height: '100%',
            }}
            springConfig={{
              duration: '0.6s',
              easeFunction: 'transform 0.6s ease-out 0s',
              delay: '0s',
            }}
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={tabIndex}
            onChangeIndex={onChangeTabIndex}
          >
            {data.map((tab, i) => (
              <InsideSalesTab key={tab.id} id={tab.id} tabIndex={tabIndex} index={i} />
            ))}
          </SwipeableViews>
        </>
      )}
    </Paper>
  );
};
