import { Sidebar } from 'components/Sidebar';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import { Button, Select, useToast } from 'components';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { RatingRiskType, RiskTypeHistoryItemViewModel } from 'schema/serverTypes';
import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  useCounterpartyRiskMutation,
  useCounterpartyRisksHistoryQuery,
} from '../../../services/api/useCounterpartiesRiskBackend';
import { RatingTypeColored } from './CounterpartyRisks';
import { useQueryClient } from 'react-query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '440px',
      minWidth: '440px',
      border: 'none',
      boxShadow: 'none',
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
    },
    headerWrapper: {
      marginBottom: theme.spacing(0.5),
    },
    header: {
      paddingTop: theme.spacing(5.5),
      fontWeight: 'bolder',
      textAlign: 'center',
      paddingRight: 40,
      paddingLeft: 40,
      paddingBottom: 20,
    },
    cardContent: {
      paddingTop: 20,
      paddingRight: 40,
      paddingBottom: 20,
      paddingLeft: 40,
      overflowY: 'auto',
      marginBottom: theme.spacing(3),
    },
    message: {
      marginBottom: theme.spacing(1.5),
      borderLeft: '1px solid' + theme.palette.secondary.main,
      padding: theme.spacing(0.5, 1),
    },
    date: {
      fontSize: 14,
      fontWeight: 400,
      marginBottom: theme.spacing(0.5),
    },
    userName: {
      color: theme.palette.text.primary,
    },
    type: {
      fontSize: 12,
      fontWeight: 500,
    },
    actions: {
      display: 'block',
      padding: theme.spacing(4, 5),
    },
    button: {
      marginTop: theme.spacing(3.5),
    },
  })
);

type CounterpartyRiskEditPanelProps = {
  isShow: boolean;
  setIsShow: Dispatch<SetStateAction<boolean>>;
  riskTypeId: number;
  riskName: string;
  ratingType: RatingRiskType;
};

export const CounterpartyRiskEditPanel = ({
  isShow,
  setIsShow,
  riskTypeId,
  riskName,
  ratingType,
}: CounterpartyRiskEditPanelProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();

  const queryClient = useQueryClient();
  const { data } = useCounterpartyRisksHistoryQuery(riskTypeId);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      ratingRisk: ratingType,
      riskTypeId: riskTypeId.toString(),
    },
  });

  const { mutateAsync } = useCounterpartyRiskMutation('', {
    method: 'PUT',
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('riskTypes') > -1;
        },
      });
      toast(t('SuccessMessage'), 'success');
    },
    onError: (_response) => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: any) => {
      try {
        await mutateAsync(form);
      } catch (error) {
        console.log(error);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const history = Array.isArray(data) ? data : [];

  if (!isShow) return null;

  return (
    <Sidebar width={440} onClose={() => setIsShow(false)}>
      <Card className={classes.root}>
        <Box className={classes.headerWrapper}>
          <CardHeader className={classes.header} title={riskName} />
        </Box>
        <CardContent className={classes.cardContent}>
          {history?.map((historyItem: RiskTypeHistoryItemViewModel) => {
            return (
              <Message {...historyItem} key={historyItem.changeDate + historyItem.ratingType} />
            );
          })}
        </CardContent>
        <CardActions className={classes.actions}>
          <form onSubmit={onSubmit}>
            <Select
              label={t('Rating')}
              name="ratingRisk"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: '',
                },
              }}
            >
              <MenuItem value="low">Низкий</MenuItem>
              <MenuItem value="medium">Средний</MenuItem>
              <MenuItem value="high">Высокий</MenuItem>
            </Select>
            <Button
              variant={'contained'}
              disabled={isSubmitting}
              type={'submit'}
              onClick={onSubmit}
              className={classes.button}
            >
              {t('Save')}
            </Button>
          </form>
        </CardActions>
      </Card>
    </Sidebar>
  );
};

const Message = ({ changeDate, ratingType, userName }: RiskTypeHistoryItemViewModel) => {
  const classes = useStyles();

  return (
    <div className={classes.message}>
      <div className={classes.date}>{changeDate}</div>
      <div className={classes.userName}>
        <Typography variant={'subtitle1'} className={classes.type} component={'span'}>
          <RatingTypeColored ratingType={ratingType} />
        </Typography>{' '}
        <span className={classes.userName}>| {userName}</span>
      </div>
    </div>
  );
};
