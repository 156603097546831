import { MenuItem, Typography } from '@material-ui/core';
import {
  Grid,
  Input,
  Button,
  Select,
  DatePicker,
  IconCheckbox,
  ColorRadio,
  useToast,
  RadioOption,
} from 'components';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { NewsCreateFieldValues, NewsMutatedFieldValues } from './types';
import { useCallback, useMemo } from 'react';
import { NewsItemViewModel } from 'schema/serverTypes';
import { useNewsBackendMutation, useNewsEditMutation } from 'services/api';
import Checkbox from '@material-ui/core/Checkbox';
import { themeOrange as theme } from 'theme';
import { NewsItemSection } from 'schema/serverTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    period: {
      color: theme.palette.text.primary,
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export type NewsCreateFormProps = {
  onSuccess: () => void;
  newsItem?: NewsItemViewModel;
};

export const NewsCreateForm = (props: NewsCreateFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onSuccess, newsItem = null } = props;

  // @ts-ignore
  const newSection = newsItem?.section.split(', ').map((item) => NewsItemSection[item]);
  const { control, handleSubmit, setValue } = useForm<NewsCreateFieldValues>({
    mode: 'all',
    defaultValues: newsItem
      ? { ...newsItem, section: newSection }
      : {
          category: 'general',
          from: '',
          isViewed: false,
          section: ['1', '2', '4', '8', '16', '32', '64', '128', '256', '512', '1024'],
          text: '',
          title: '',
          to: '',
        },
  });
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync } = useNewsBackendMutation<NewsMutatedFieldValues, NewsItemViewModel>({
    onSuccess: () => {
      toast(t('NewsSuccessMessage'), 'success');
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const { mutateAsync: mutateAsyncEdit, isLoading: disabled } = useNewsEditMutation(
    `${newsItem?.id}`,
    {
      method: 'PUT',
    }
  );

  const onSubmit = useMemo(() => {
    const submit = async (form: NewsCreateFieldValues) => {
      try {
        const { section } = form;
        const newSection = section.reduce(
          (previousValue, currentValue) => previousValue + parseInt(currentValue, 10),
          0
        );
        const mutate = newsItem ? mutateAsyncEdit : mutateAsync;
        const newsData = await mutate({ ...form, section: newSection + '' });
        if (newsData) {
          await queryClient.invalidateQueries({
            predicate: (query) => {
              return (
                typeof query.queryKey === 'string' &&
                (query.queryKey === 'newsFeed' || query.queryKey.startsWith('criticalNews'))
              );
            },
          });
        }
        onSuccess();
      } catch (error) {
        console.log(error);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, mutateAsyncEdit, newsItem, onSuccess, queryClient]);

  const category = useWatch({ control, name: 'category' });
  const section = useWatch({ control, name: 'section' });

  const handleSelectAll = useCallback(() => {
    const curSection = section.reduce(
      (previousValue, currentValue) => previousValue + parseInt(currentValue, 10),
      0
    );
    const nextSection =
      curSection === 2047
        ? []
        : ['1', '2', '4', '8', '16', '32', '64', '128', '256', '512', '1024', '0'];
    setTimeout(() => setValue('section', nextSection), 0);
  }, [section, setValue]);

  return (
    <form onSubmit={onSubmit}>
      <Typography variant={'h2'} className={classes.title}>
        {newsItem ? t('Edit news') : t('Add news')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          <Select label={t('Category2')} name="category" control={control}>
            <MenuItem value="general">{t('general')}</MenuItem>
            <MenuItem value="critical">{t('critical')}</MenuItem>
          </Select>
        </Grid>
        {category === 'critical' && (
          <>
            <Grid item md={24} xs={24}>
              <Select
                label={t('Section.title')}
                name="section"
                control={control}
                renderValue={(section: any) => {
                  return section
                    .map((value: string) => {
                      const keyByValue = Object.keys(NewsItemSection).find(
                        (key: string) => (NewsItemSection as any)[key] === value
                      );
                      switch (keyByValue) {
                        case 'all':
                          return t(`Section.all`);
                        case 'title':
                          return t(`Section.title`);
                        case 'home':
                          return t(`Section.home`);
                        case 'users':
                          return t(`Section.users`);
                        case 'quotas':
                          return t(`Section.quotas`);
                        case 'counterparties':
                          return t(`Section.counterparties`);
                        case 'news':
                          return t(`Section.news`);
                        case 'templates':
                          return t(`Section.templates`);
                        case 'issues':
                          return t(`Section.issues`);
                        case 'dictionaries':
                          return t(`Section.dictionaries`);
                        case 'missing':
                          return t(`Section.missing`);
                        case 'wiki':
                          return 'Wiki';
                        case 'contracts':
                          return t(`Section.contracts`);
                        default:
                          return '';
                      }
                    })
                    .join(', ');
                }}
                multiple
              >
                <MenuItem value={'0'} onClick={handleSelectAll}>
                  <Checkbox
                    icon={<IconCheckbox checked={false} />}
                    checkedIcon={<IconCheckbox checked={true} />}
                    checked={
                      section.reduce(
                        (previousValue, currentValue) => previousValue + parseInt(currentValue, 10),
                        0
                      ) === 2047
                    }
                  />
                  {t('Section.all')}
                </MenuItem>
                {Object.entries(NewsItemSection).map((item) => {
                  let label;
                  switch (item[0]) {
                    case 'all':
                      label = t(`Section.all`);
                      break;
                    case 'title':
                      label = t(`Section.title`);
                      break;
                    case 'home':
                      label = t(`Section.home`);
                      break;
                    case 'users':
                      label = t(`Section.users`);
                      break;
                    case 'quotas':
                      label = t(`Section.quotas`);
                      break;
                    case 'counterparties':
                      label = t(`Section.counterparties`);
                      break;
                    case 'news':
                      label = t(`Section.news`);
                      break;
                    case 'templates':
                      label = t(`Section.templates`);
                      break;
                    case 'issues':
                      label = t(`Section.issues`);
                      break;
                    case 'dictionaries':
                      label = t(`Section.dictionaries`);
                      break;
                    case 'missing':
                      label = t(`Section.missing`);
                      break;
                    case 'wiki':
                      label = 'Wiki';
                      break;
                    case 'contracts':
                      label = t(`Section.contracts`);
                      break;
                  }
                  return (
                    <MenuItem value={item[1]} key={item[0]}>
                      <Checkbox
                        icon={<IconCheckbox checked={false} />}
                        checkedIcon={<IconCheckbox checked={true} />}
                        checked={section.indexOf(item[1]) > -1}
                      />
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item md={6} xs={24} className={classes.period}>
              {t('Period of activity')}
            </Grid>
            <Grid item md={9} xs={24}>
              <DatePicker label={t('From')} control={control} name="from" disablePast={true} />
            </Grid>
            <Grid item md={9} xs={24}>
              <DatePicker label={t('To')} control={control} name="to" disablePast={true} />
            </Grid>
          </>
        )}
        <Grid item md={24} xs={24}>
          <Input
            label={t('Title')}
            control={control}
            name="title"
            multiline={true}
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />
        </Grid>
        {category === 'critical' && (
          <Grid item md={24} xs={24}>
            <ColorRadio
              label={t('Background color')}
              name={'color'}
              control={control}
              defaultValue={`${theme.palette.error.main}`}
              options={[
                { value: theme.palette.primary.main } as RadioOption,
                { value: theme.palette.secondary.dark } as RadioOption,
                { value: theme.palette.secondary.main } as RadioOption,
                { value: '#6750A3' } as RadioOption,
                { value: theme.palette.error.main } as RadioOption,
                { value: '#F27400' } as RadioOption,
                { value: theme.palette.attention.main } as RadioOption,
                { value: theme.palette.green.main } as RadioOption,
              ]}
            />
          </Grid>
        )}
        {category === 'general' && (
          <Grid item md={24} xs={24}>
            <Input
              label={t('Text')}
              control={control}
              name="text"
              multiline={true}
              rules={{
                required: {
                  value: true,
                  message: t('Required'),
                },
              }}
            />
          </Grid>
        )}
        <Grid item md={24} xs={24}>
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
            disabled={disabled}
          >
            {t('Save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
