import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { IssueStatus, IssueType, TaskViewModel, UpdateIssueStatusModel } from 'schema/serverTypes';
import { useSourceString, useStatusSelectString, useIssueTypeString } from './useStatusString';
import { useLanguage } from '../utils/useLanguage';
import { Button } from '../Button';
import { useIssueBackendMutation } from '../../services/api';
import { BaseSyntheticEvent, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import { User } from '../users';

export type TaskItemProps = TaskViewModel;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      position: 'relative',
      padding: '17px 12px',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      borderBottom: `1px solid ${theme.palette.background.default}`,
      '&:hover': {
        backgroundColor: theme.palette.background.light,
        cursor: 'pointer',
        '& $title': {
          color: theme.palette.primary.main,
          textDecoration: 'underline',
        },
        '& $execute': {
          display: 'block',
        },

        '& $ownerTitle': {
          display: (props: TaskItemProps) => (props.assignee ? '' : 'none'),
        },
        '& $owner': {
          display: (props: TaskItemProps) => (props.assignee ? '' : 'none'),
        },
        '& $date ': {
          display: (props: TaskItemProps) => (props.assignee ? '' : 'none'),
        },
      },
    },
    closed: {},
    titleWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: 8,
      '&$closed': {
        '& $id': {
          textDecoration: 'line-through',
        },
        '& $title': {
          color: theme.palette.text.primary,
          pointerEvents: 'none',
        },
      },
    },
    id: {
      marginRight: 8,
      fontSize: 14,
    },
    title: {
      width: '83%',
      color: theme.palette.secondary.dark,
      fontSize: 14,
      textDecoration: 'none',
    },
    description: {
      width: '83%',
      fontSize: 12,
      color: theme.palette.common.black,
      marginBottom: 12,
      '& p': {
        margin: 0,
        paddingBottom: theme.spacing(1.5),
        '&:last-child': {
          paddingBottom: theme.spacing(0),
        },
      },
      '& ol': {
        paddingLeft: theme.spacing(2),
        '& > li': {
          listStyleType: 'decimal',
          marginBottom: theme.spacing(1),
        },
      },
      '& ul': {
        paddingLeft: theme.spacing(2),
        '& > li': {
          listStyle: 'initial',
          marginBottom: theme.spacing(1),
        },
      },
    },
    users: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    createWrapper: {
      display: 'flex',
      color: theme.palette.text.secondary,
      fontSize: 12,
    },
    createTitle: {
      width: 140,
    },
    employee: {
      borderLeft: `1px solid ${theme.palette.secondary.main}`,
      paddingLeft: 8,
      marginLeft: 8,
      minWidth: 216,
    },
    status: {
      borderLeft: `1px solid ${theme.palette.secondary.main}`,
      paddingLeft: 8,
      marginLeft: 8,
      minWidth: 160,
      '&:before': {
        content: '""',
        display: 'inline-block',
        width: 8,
        height: 8,
        borderRadius: '50%',
        marginRight: theme.spacing(0.5),
        backgroundColor: 'black',
      },
      '&.closed:before': {
        backgroundColor: theme.palette.green.main,
      },
      '&.opened:before': {
        backgroundColor: theme.palette.primary.main,
      },
      '&.inProgress:before': {
        backgroundColor: theme.palette.attention.main,
      },
      '&.forReview:before': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&.postponed:before': {
        backgroundColor: theme.palette.error.main,
      },
      '&.returned:before': {
        backgroundColor: theme.palette.error.main,
      },
      '&.verification:before': {
        backgroundColor: theme.palette.darkAttention.main,
      },
      '&.rework:before': {
        backgroundColor: theme.palette.attention.main,
      },
    },
    ownerWrapper: {
      display: 'flex',
      position: 'relative',
    },
    ownerTitle: {
      display: () => 'block',
      position: 'absolute',
      top: -20,
      right: 0,
      fontSize: 10,
      color: theme.palette.text.secondary,
    },
    owner: {
      display: () => 'block',
      fontSize: 12,
    },
    execute: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: theme.spacing(1),
      display: 'none',
    },
    date: {
      display: () => 'block',
      borderLeft: `1px solid ${theme.palette.secondary.main}`,
      paddingLeft: 8,
      marginLeft: 8,
      fontSize: 12,
    },
    statusTime: {
      display: 'block',
      borderLeft: `1px solid ${theme.palette.secondary.main}`,
      paddingLeft: 8,
      marginLeft: 8,
      minWidth: 120,
    },
    alert: {
      fontWeight: 400,
      color: theme.palette.error.main,
    },
  })
);

const humanTime = (time: string | undefined) => {
  if (!time) return '?';
  const ti = moment.duration(time);
  const days = ti.days();
  const hours = ti.hours();
  const minutes = ti.minutes();
  const seconds = ti.seconds();
  if (days > 0) {
    return `${days} д ${hours} ч ${minutes} м`;
  }
  if (minutes > 0) {
    return `${hours}ч ${minutes}м`;
  }

  return `0м ${seconds}с`;
};

export const TaskItem = (props: TaskItemProps) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const lang = useLanguage();
  const {
    id,
    title,
    description = '',
    author,
    status,
    assignee,
    issueType,
    issueSource,
    createdDate,
    updatedDate,
    timeInLastStatus,
    timeInProgressStatus,
    canBeTaken,
  } = props;

  const tiInLastStatus = moment.duration(timeInLastStatus);
  const hours = tiInLastStatus.hours() + tiInLastStatus.days() * 24;
  const alertTimeClass =
    (status === IssueStatus.Opened && hours >= 2) ||
    (status === IssueStatus.InProgress && hours >= 8)
      ? classes.alert
      : '';

  const statusSelectString = useStatusSelectString(status);
  const sourceString = useSourceString(issueSource);
  const typeString = useIssueTypeString(issueType);

  const statusClass = status === 'closed' ? classes.closed : '';
  const { push } = useHistory();
  const queryClient = useQueryClient();

  const { mutateAsync: mutateAsyncStatus } = useIssueBackendMutation<
    UpdateIssueStatusModel,
    UpdateIssueStatusModel
  >(`${id}/status`, {
    method: 'PUT',
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: (query) => {
          return typeof query.queryKey === 'string' && query.queryKey.indexOf(`issues`) >= 0;
        },
      });
      push(`/tasks/${id}`);
    },
  });

  const handleExecute = async (ev: BaseSyntheticEvent) => {
    ev.stopPropagation();
    ev.preventDefault();
    mutateAsyncStatus({ status: IssueStatus.InProgress });
  };

  const { pathname } = useLocation();

  const calculateTitle = useCallback(() => {
    let label = '';
    let extra = '';

    switch (issueType) {
      case IssueType.Contract:
        label = t('Calculation');
        break;
      case IssueType.Verification:
        label = t('Verification of the contract');
        break;
      case IssueType.Error:
        label = t('Error message');
        extra = `| ${sourceString ?? ''} |`;
        break;
      default:
        break;
    }

    return `${label} ${extra} ${title}`;
  }, [issueType, t, sourceString, title]);

  return (
    <Link to={`${pathname}/${id}`} className={classes.root}>
      <div className={clsx(classes.titleWrapper, statusClass)}>
        <div className={classes.id}>{id}</div>
        <div className={classes.title}>{calculateTitle()}</div>
      </div>
      <div
        className={classes.description}
        dangerouslySetInnerHTML={{
          __html: description?.length > 200 ? description.substr(0, 200) + '...' : description,
        }}
      />
      <div className={classes.users}>
        <div className={classes.createWrapper}>
          <div className={classes.createTitle}>{typeString}</div>
          <div className={classes.employee}>
            {assignee ? <User name={assignee.name} isActive={assignee.isActive} /> : '—'}
          </div>
          <div className={clsx(classes.status, status)}>{statusSelectString}</div>
          <div className={classes.statusTime}>
            {status === IssueStatus.Closed && updatedDate
              ? dayjs(updatedDate).locale(lang).format('DD MMM YYYY - HH:mm')
              : '?'}
          </div>
          <div className={clsx(classes.statusTime, alertTimeClass)}>
            {humanTime(status === IssueStatus.Closed ? timeInProgressStatus : timeInLastStatus)}
          </div>
        </div>

        <div className={classes.ownerWrapper}>
          <div className={classes.ownerTitle}>{t('Set')}</div>
          <div className={classes.owner}>
            <User name={author.name} isActive={author.isActive} />
          </div>
          <div className={clsx(classes.date, alertTimeClass)}>
            {dayjs(createdDate).locale(lang).format('DD MMM YYYY HH:mm')}
          </div>
        </div>
        {!assignee && canBeTaken && (
          <Button
            className={classes.execute}
            color="primary"
            size="medium"
            type="button"
            variant="contained"
            onClick={handleExecute}
          >
            {t('Execute')}
          </Button>
        )}
      </div>
    </Link>
  );
};
