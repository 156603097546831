import React from 'react';
import { ContractViewModel, QuotaCalculationResult, ScoringModelId } from 'schema/serverTypes';
import { useQuotasBackendQuery, useScoringQuery } from 'services';
import { useParams } from 'react-router-dom';
import { SimpleForm } from './SimpleForm';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Button, IconBackTo } from 'components';
import { ScoringModelForm } from './ScoringModelForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quotaInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1.5),
    },
    span: {
      fontFamily: 'Roboto, san-serif',
      fontSize: 12,
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(1.6),
    },
  })
);

export const DrawUpContract = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const url = `${id}`;
  const { data: quota } = useQuotasBackendQuery<QuotaCalculationResult>(url, ['quotas', id], {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const { data: contract } = useQuotasBackendQuery<ContractViewModel>(
    `${id}/contract`,
    ['quotas', id, 'contract'],
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: scoringModels = [] } = useScoringQuery(parseInt(id, 10), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  if (!quota || !contract || !scoringModels) return null;

  const scoringModel = scoringModels.find((model) => model.name === contract.scoringModelName);

  return (
    <>
      <div className={classes.quotaInfo}>
        <div>
          <Button variant="iconButton" endIcon={<IconBackTo />} to={`/quotas/calculator/${id}`} />
          <span className={classes.span}>
            {t('Draw up contract')} | {t('Calculation')} № {id}
          </span>
        </div>
      </div>
      {scoringModel?.result.isValid && scoringModel?.id === ScoringModelId.Retail ? (
        <ScoringModelForm
          quota={quota}
          contract={contract}
          prepaymentPercents={contract.prepaymentPercents}
        />
      ) : (
        <SimpleForm quota={quota} contract={contract} />
      )}
    </>
  );
};
