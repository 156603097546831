import { useTranslation } from 'react-i18next';
import { IssueSource, IssueStatus, IssueType } from 'schema/serverTypes';

export const useStatusSelectString = (status: IssueStatus) => {
  const { t } = useTranslation();

  switch (status) {
    case IssueStatus.Opened:
      return t('IssueStatus.Opened');
    case IssueStatus.Closed:
      return t('IssueStatus.Closed');
    case IssueStatus.ForReview:
      return t('IssueStatus.ForReview');
    case IssueStatus.InProgress:
      return t('IssueStatus.InProgress');
    case IssueStatus.Postponed:
      return t('IssueStatus.Postponed');
    case IssueStatus.Returned:
      return t('IssueStatus.Returned');
    case IssueStatus.Rework:
      return t('IssueStatus.Rework');
    case IssueStatus.Verification:
      return t('IssueStatus.Verification');
    case IssueStatus.InQueue:
      return t('IssueStatus.InQueue');
    default:
      return t('Unknown');
  }
};

export const useStatusHistoryString = (status: IssueStatus) => {
  const { t } = useTranslation();

  switch (status) {
    case IssueStatus.Opened:
      return t('IssueStatusHistory.Opened');
    case IssueStatus.Closed:
      return t('IssueStatusHistory.Closed');
    case IssueStatus.ForReview:
      return t('IssueStatusHistory.ForReview');
    case IssueStatus.InProgress:
      return t('IssueStatusHistory.InProgress');
    case IssueStatus.Postponed:
      return t('IssueStatusHistory.Postponed');
    case IssueStatus.Returned:
      return t('IssueStatusHistory.Returned');
    case IssueStatus.InQueue:
      return t('IssueStatusHistory.InQueue');
    default:
      return status;
  }
};

export const useSourceString = (type: IssueSource) => {
  const { t } = useTranslation();
  switch (type) {
    case IssueSource.quota:
      return t('IssueSource.quota');
    case IssueSource.shipment:
      return t('IssueSource.contract');
    case IssueSource.home:
      return t('IssueSource.home');
    case IssueSource.counterparty:
      return t('IssueSource.counterparty');
    case IssueSource.template:
      return t('IssueSource.template');
    case IssueSource.user:
      return t('IssueSource.user');
    case IssueSource.dictionary:
      return t('IssueSource.dictionary');
    case IssueSource.news:
      return t('IssueSource.news');
    case IssueSource.issues:
      return t('IssueSource.issues');
  }
};

export const useIssueTypeString = (type: IssueType) => {
  const { t } = useTranslation();
  switch (type) {
    case IssueType.Contract:
      return t('IssueType.Contract');
    case IssueType.Error:
      return t('IssueType.Error');
    case IssueType.Telematics:
      return t('IssueType.Telematics');
    case IssueType.Payment:
      return t('IssueType.Payment');
    case IssueType.Shipment:
      return t('IssueType.Shipment');
    case IssueType.Rework:
      return t('IssueType.Rework');
    case IssueType.Verification:
      return t('IssueType.Verification');
    case IssueType.ShipmentReport:
      return t('IssueType.ShipmentReport');
    case IssueType.Nomenclature:
      return t('IssueType.Nomenclature');
    case IssueType.Scoring:
      return t('IssueType.Scoring');
    case IssueType.Approval:
      return t('IssueType.Approval');
    default:
      return t('Unknown');
  }
};
