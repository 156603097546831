import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import { Tabs, useTabs, DownloadTemplateList, TabPanel } from 'components/index';
import SwipeableViews from 'react-swipeable-views';
import { PipelineItem } from './PipelineItem';
import { PipelineFilter } from './PipelineFilter';
import { useCurrentUserTabsQuery } from 'services/api/useApplicationUsersBackend';
import { useNewPipelineReportQuery } from 'services/api/useReportsBackend';
import { ApplicationUserTab, TemplateKind } from 'schema/serverTypes';
import { Empty } from '../../../Empty';
import { endOfMonth, startOfMonth } from '../../Shipment/dates';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1.5, 2.5),
      // minHeight: 388,
      // height: '100%',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 30.5,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      position: 'relative',
      paddingRight: 120,
    },
    tabs: {
      marginBottom: -2,
    },
    head: {
      fontSize: 12,
      fontWeight: 400,
      padding: 6,
      '&$managers': {
        color: theme.palette.text.primary,
        fontSize: 12,
        fontWeight: 700,
        textAlign: 'left',
      },
      '&$shipment': {
        textAlign: 'center',
      },
      '&.MuiTableCell-root:first-child': {
        padding: 6,
      },
      '&.MuiTableCell-root:last-child': {
        padding: 6,
      },
    },
    managers: {},
    border: {
      '&.MuiTable-root': {
        borderCollapse: 'initial',
      },
    },
    pipeline: {
      display: 'inline-block',
      marginBottom: theme.spacing(1),
    },
    shipment: {},
  })
);

export const PipelineContainer = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState<string | undefined>(startOfMonth);
  const [endDate, setEndDate] = useState<string | undefined>(endOfMonth);
  const [salesChannel, setSalesChannel] = useState<string | undefined>();
  const { t } = useTranslation();

  const { data = [], isLoading } = useCurrentUserTabsQuery();

  const groups = data.filter((t) => t.id !== ApplicationUserTab.mine);

  const tabs = useMemo(() => {
    return groups
      .filter((t) => t.id !== ApplicationUserTab.mine)
      .map((group) => {
        return group.name.length >= 15 ? `${group.name.substr(0, 11)}...` : group.name;
      });
  }, [groups]);
  const tabsProps = useTabs(tabs);
  const { tabIndex, onChangeTabIndex, onChangeTab } = tabsProps;

  return (
    <>
      <Paper className={classes.root}>
        <Box className={classes.header}>
          <Typography variant="subtitle1" className={classes.pipeline}>
            {t('Pipeline Reports')}
            <DownloadTemplateList kind={TemplateKind.Pipeline} />
          </Typography>
          {tabs.length !== 0 && (
            <Tabs
              {...tabsProps}
              orientation={tabs.length > 9 ? 'vertical' : 'horizontal'}
              value={tabIndex}
              onChangeTab={onChangeTab}
              className={classes.tabs}
            />
          )}
          <div />
        </Box>
        {isLoading ? (
          <Empty></Empty>
        ) : (
          <>
            <PipelineFilter
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setSalesChannel={setSalesChannel}
            />
            <SwipeableViews
              containerStyle={{
                transition: 'transform 0.6s ease-out 0s',
              }}
              springConfig={{
                duration: '0.6s',
                easeFunction: 'transform 0.6s ease-out 0s',
                delay: '0s',
              }}
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={tabIndex}
              onChangeIndex={onChangeTabIndex}
            >
              {groups.map((group, i) => {
                return (
                  <TabContent
                    key={group.id}
                    tabId={group.id}
                    tabIndex={tabIndex}
                    index={i}
                    startDate={startDate}
                    endDate={endDate}
                    salesChannel={salesChannel}
                  />
                );
              })}
            </SwipeableViews>
          </>
        )}
      </Paper>
    </>
  );
};

type TabContentProps = {
  tabId: number;
  startDate?: string;
  endDate?: string;
  salesChannel?: string;
  tabIndex: number;
  index: number;
};

const TabContent = (props: TabContentProps) => {
  const { t } = useTranslation();

  const { tabId, index, tabIndex, startDate: start, endDate: end, salesChannel } = props;

  const { data: items, isLoading } = useNewPipelineReportQuery(
    { start, end, tabId, salesChannel },
    {
      enabled: index === tabIndex,
    }
  );

  if (isLoading || items === undefined) {
    return <Empty>{t('Loading')}</Empty>;
  }

  return (
    <TabPanel value={tabIndex} index={index} dir={theme.direction}>
      {items.map((item) => (
        <PipelineItem key={item.name} {...item} />
      ))}
    </TabPanel>
  );
};
