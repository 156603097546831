import { QueryKey } from 'react-query';
import {
  ApplicationUserTab,
  AssigneeReportViewModel,
  NotAssignedIssuesViewModel,
  ShipmentItemGroup,
  ShipmentItemsReportRequest,
  ShipmentReportRequest,
  UserGroupShipmentViewModel,
  NewPipelineReportRequest,
  NewPipelineReportGroupItem,
} from 'schema/serverTypes';
import { useBackendQuery, QueryOptions } from '.';
import { calculationUrl } from 'services/urls';
import { useMemo } from 'react';

const createUrlFromParts = (relativeUrl?: string, searchParams?: URLSearchParams) => {
  const urlParts = relativeUrl ? relativeUrl.trim().split('/') : [];
  const parts = [calculationUrl, 'api', 'v1', 'reports', ...urlParts].filter((t) => t !== '');
  let url = parts.join('/');
  url = searchParams ? `${url}?${searchParams.toString()}` : url;
  return url;
};

const createOptions = <TResponse, TQueryKey extends QueryKey = QueryKey>(
  options: QueryOptions<TResponse, TQueryKey> | undefined = {},
) => {
  const defaultOptions: QueryOptions<TResponse, TQueryKey> = {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    keepPreviousData: false,
  };
  return {
    ...defaultOptions,
    ...options,
  } as QueryOptions<TResponse, TQueryKey>;
};

export type ReportsBackendQueryOptions<TResponse, TQueryKey extends QueryKey = QueryKey> = {
  relativeUrl?: string;
  searchParams?: URLSearchParams;
  options?: QueryOptions<TResponse, TQueryKey>;
};

export const useReportsBackendQuery = <TResponse>(
  options: ReportsBackendQueryOptions<TResponse, string>,
) => {
  const url = createUrlFromParts(options.relativeUrl, options.searchParams);
  return useBackendQuery(
    createUrlFromParts(options.relativeUrl, options.searchParams),
    url,
    createOptions(options.options),
  );
};

export const useAssignessReportRequest = (
  isInProgress: boolean,
  startDate?: string,
  endDate?: string,
  count?: number,
) => {
  const searchParams = new URLSearchParams();

  if (isInProgress) {
    searchParams.append('isInProgress', 'true');
  }

  if (!isInProgress && startDate && endDate) {
    searchParams.append('fromDate', startDate);
    searchParams.append('toDate', endDate);
  }

  if (count !== undefined && count > 0) {
    searchParams.append('count', count.toString());
  }

  return useReportsBackendQuery<AssigneeReportViewModel[]>({
    relativeUrl: 'assignees',
    searchParams,
  });
};

export const useNotAssignedIssuesCountReportRequest = (longRunTime?: string) => {
  const relativeUrl = 'notAssigned';
  let searchParams: URLSearchParams | undefined = undefined;

  if (longRunTime !== undefined && longRunTime !== '') {
    searchParams = new URLSearchParams();
    searchParams.append('longRunTime', longRunTime);
  }

  return useReportsBackendQuery<NotAssignedIssuesViewModel>({
    relativeUrl,
    searchParams,
  });
};

export const useShipmentItemsReportQuery = (
  request: ShipmentItemsReportRequest,
  options: QueryOptions<ShipmentItemGroup, string> | undefined = undefined,
) => {
  const { start, end, expiredOnly, tabId = ApplicationUserTab.all } = request;

  let searchParams = useMemo(() => {
    let searchParams = new URLSearchParams();
    if (start) {
      searchParams.append('start', start);
    }
    if (end) {
      searchParams.append('end', end);
    }
    if (expiredOnly) {
      searchParams.append('expiredOnly', expiredOnly === true ? 'true' : 'false');
    }
    searchParams.append('tabId', tabId.toString());

    return searchParams;
  }, [start, end, expiredOnly, tabId]);

  return useReportsBackendQuery<ShipmentItemGroup>({
    relativeUrl: 'shipment',
    options,
    searchParams,
  });
};

export const usePipelineReportQuery = (
  request: ShipmentReportRequest,
  options: QueryOptions<UserGroupShipmentViewModel, string> | undefined = undefined,
) => {
  const { start, end, tabId = ApplicationUserTab.all } = request;

  const searchParams = useMemo(() => {
    let searchParams = new URLSearchParams();
    if (start) {
      searchParams.append('start', start);
    }
    if (end) {
      searchParams.append('end', end);
    }
    searchParams.append('tabId', tabId.toString());

    return searchParams;
  }, [start, end, tabId]);

  return useReportsBackendQuery<UserGroupShipmentViewModel>({
    relativeUrl: 'pipeline',
    options,
    searchParams,
  });
};

export const useNewPipelineReportQuery = (
  request: NewPipelineReportRequest,
  options: QueryOptions<NewPipelineReportGroupItem[], string> | undefined = undefined,
) => {
  const { start, end, tabId = ApplicationUserTab.all, salesChannel, isAddAll } = request;

  const searchParams = useMemo(() => {
    let searchParams = new URLSearchParams();
    if (start) {
      searchParams.append('start', start);
    }
    if (end) {
      searchParams.append('end', end);
    }
    if (salesChannel && salesChannel !== ' ') {
      searchParams.append('salesChannel', salesChannel);
    }
    searchParams.append('tabId', tabId.toString());

    if (isAddAll) {
      searchParams.append('isAddAll', isAddAll.toString());
    }

    return searchParams;
  }, [start, end, tabId, salesChannel, isAddAll]);

  return useReportsBackendQuery<NewPipelineReportGroupItem[]>({
    relativeUrl: 'pipeline2',
    options,
    searchParams,
  });
};
