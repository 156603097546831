import { FieldError, useController } from 'react-hook-form';
import { useCalculationFormContext } from './types';
import { useTranslation } from 'react-i18next';
import { useRequiredOnSave } from './useRequiredOnSave';

import { useDictionaryBackendQuery } from 'services/api';
import { Autocomplete, IconSprite } from 'components';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { Box, InputAdornment } from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import { useCallback, useMemo, useRef, useState } from 'react';
import { CounterpartyOption } from 'schema/serverTypes';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { useDebounce } from 'use-debounce';

type CounterpartyType = 'dealer' | 'insuranceCompany';

const getOptionLabel = (option: CounterpartyOption | null) => {
  if (option === null) {
    return '';
  }
  return `${option.inn} (${option.name})`;
};

const getOptionSelected = (option: CounterpartyOption | null, value: CounterpartyOption | null) => {
  if (option === null || value === null) {
    return false;
  }
  return option.inn === value.inn;
};

export type CounterpartyAutocompleteProps = {
  type: CounterpartyType;
  required?: boolean;
};

const useRequestUrl = (type: CounterpartyType, input: string) => {
  return useMemo(() => {
    let requestUrl = 'counterparties';
    const searchParams = new URLSearchParams();
    searchParams.set('type', type);
    if (input) {
      searchParams.set('name', input);
    }
    requestUrl += `?${searchParams}`;

    return requestUrl;
  }, [type, input]);
};

export const CounterpartyAutocomplete = (props: CounterpartyAutocompleteProps) => {
  const { type, required = true } = props;
  const { t } = useTranslation();

  const { control, setValue } = useCalculationFormContext();
  const rules = useRequiredOnSave({ name: type });
  const {
    field: { onChange, value = null },
    fieldState: { error },
  } = useController({
    control,
    name: type,
    rules: required ? rules : undefined,
  });
  const helperText = error !== undefined ? (error as FieldError).message : undefined;
  const invalid = error !== undefined;
  const [inputValue, setInputValue] = useState('');
  const initialSet = useRef(false);
  const [changedOption, setChangedOption] = useState<CounterpartyOption[] | null>(null);
  const [input] = useDebounce(inputValue, 300);
  const requestUrl = useRequestUrl(type, input ?? '');
  const { data: optionsFromData = [], refetch } =
    useDictionaryBackendQuery<CounterpartyOption[]>(requestUrl);

  const { data: optionsByInn = [] } = useDictionaryBackendQuery<CounterpartyOption[]>(
    useRequestUrl(type, value ?? '')
  );

  const options = changedOption || optionsFromData;

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason !== 'reset') {
        setInputValue(value);
      } else if (!initialSet.current) {
        initialSet.current = true;
        setInputValue(value);
      }
    },
    [setInputValue]
  );

  const nameFieldPath = type === 'dealer' ? 'dealerName' : 'insuranceCompanyName';
  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: CounterpartyOption | null,
      reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<CounterpartyOption | null>
    ) => {
      if (reason === 'clear') {
        onChange(undefined);
        setValue(nameFieldPath, undefined, { shouldValidate: true });
        setValue(type, '', { shouldValidate: true });
        setInputValue('');
        setChangedOption(null);
        refetch();
      } else {
        if (value !== null) {
          onChange(value.inn);
          setValue(nameFieldPath, value.name, { shouldValidate: true });
          setInputValue(getOptionLabel(value));
          setChangedOption([{ name: value.name, inn: value.inn, ratingGrade: value.ratingGrade }]);
        } else {
          onChange(undefined);
          setInputValue('');
          setValue(nameFieldPath, '', { shouldValidate: true });
          setChangedOption(null);
          refetch();
        }
      }
    },
    [onChange, refetch, setInputValue, setValue, nameFieldPath, type]
  );

  const label = type === 'dealer' ? t('Dealer') : t('InsuranceCompany');

  const iconColor = value ? theme.palette.text.primary : theme.palette.text.primary;
  const iconHoverColor = value ? theme.palette.primary.main : theme.palette.text.primary;
  const selectedOption = [...options, ...optionsByInn].find((t) => t.inn === value);
  const counterpartyLink = value ? `/counterparties/${value}` : undefined;

  const icon = (
    <Box p={1} pt={1.4} pr={0}>
      <IconSprite
        icon={'view'}
        width="15px"
        height="10px"
        color={iconColor}
        hoverColor={iconHoverColor}
      />
    </Box>
  );

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box flexGrow={1}>
        <Autocomplete<CounterpartyOption | null>
          label={label}
          options={options}
          inputValue={inputValue}
          onInputChange={handleOnInputChange}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          onChange={handleOnChange}
          error={invalid}
          helperText={helperText}
          value={selectedOption ?? null}
          popupIcon={
            <KeyboardArrowDownRoundedIcon {...props} htmlColor={theme.palette.text.primary} />
          }
          InputProps={{
            name: type,
            startAdornment: counterpartyLink ? (
              <InputAdornment position="start">
                <a href={counterpartyLink} target="_blank" rel="noreferrer">
                  {icon}
                </a>
              </InputAdornment>
            ) : null,
          }}
        />
      </Box>
    </Box>
  );
};
