import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@material-ui/pickers';
import { useController, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { IconSprite } from 'components';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import formControlStyles from 'theme/overrides/MuiFormControl';
import formLabelStyles from 'theme/overrides/MuiFormLabel';
import inputBaseStyles from 'theme/overrides/MuiInputBase';
import { themeOrange as theme } from 'theme';
import typography from 'theme/typography';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, FormHelperText, makeStyles, Theme } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& [class*="MuiPickersBasePicker-container"]': {
        backgroundColor: theme.palette.bgGray.main,
      },
    },
  })
);

const calendarTheme = createTheme({
  palette: theme.palette,
  typography,
  overrides: {
    MuiFormControl: formControlStyles,
    MuiFormLabel: formLabelStyles,
    MuiInputBase: inputBaseStyles,
    MuiTypography: {
      subtitle1: {
        color: theme.palette.common.white,
        fontWeight: 300,
      },
      h5: {
        color: theme.palette.primary.main,
        fontSize: '14px',
        fontWeight: 300,
        lineHeight: '18px',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: theme.palette.primary.main,
        fontSize: '14px',
      },
      iconButton: {
        borderRadius: 0,
        backgroundColor: 'transparent',
        color: theme.palette.secondary.main,
      },
      transitionContainer: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
          top: 'auto',
          color: theme.palette.common.black,
          fontSize: 14,
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        borderBottom: '1px solid grey',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '& *[class*="MuiPickersToolbarText-toolbarTxt-"]': {
          color: theme.palette.common.white,
        },
      },
    },
    MuiPickersDay: {
      day: {
        color: theme.palette.common.black,
        borderRadius: '50%',
        '&:hover': {
          border: `1px solid ${theme.palette.blueGrey.main}`,
          backgroundColor: theme.palette.blueGrey.main,
        },
      },
      hidden: {
        color: theme.palette.text.primary,
        opacity: 1,
      },
    },
    MuiPickersMonth: {
      root: {
        color: theme.palette.common.black,
        fontSize: '14px',
        '&:hover': {
          border: '1px solid ' + theme.palette.secondary.dark,
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.common.black,
        },
      },
      monthSelected: {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.primaryGradient[200],
        fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
        fontSize: 14,
      },
      monthDisabled: {
        color: theme.palette.text.disabled,
        fontSize: '12px',
      },
    },
    MuiPickersYear: {
      root: {
        color: `${theme.palette.common.black} !important`,
        '&:hover': {
          color: theme.palette.common.black,
        },
      },
      yearSelected: {
        color: theme.palette.common.black,
        fontFamily: "'Halvar Breit', Helvetica, Arial, sans-serif",
      },
      monthSelected: {
        color: theme.palette.common.black,
      },
    },
    MuiInputLabel: {
      outlined: {
        '&$shrink': {
          transform: 'translate(9px, -6px) scale(0.75)',
        },
      },
    },
  },
});

export type DatePickerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> &
  Omit<MuiDatePickerProps, 'name' | 'value' | 'onChange' | 'defaultValue'> & {
    onDateChange?: (date: string | null) => void;
  };

export function DatePicker<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: DatePickerProps<TFieldValues, TName>) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    okLabel = t('Ok'),
    clearLabel = t('Clear'),
    cancelLabel = t('Cancel'),
    invalidDateMessage = t('Invalid date format'),
    variant = 'dialog',
    clearable = true,
    inputVariant = 'outlined',
    autoOk = true,
    size = 'small',
    fullWidth = true,
    format = 'DD.MM.yyyy',
    control,
    name,
    shouldUnregister,
    rules,
    defaultValue,
    onDateChange,
    orientation = 'landscape',
    ...rest
  } = props;

  const {
    field: { ref, value: dateValue, onChange, ...inputProps },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    control,
    name,
    shouldUnregister,
    rules,
    defaultValue,
  });

  const handleOnChange = useCallback(
    (date: MaterialUiPickersDate) => {
      const value = date ? date?.toISOString() : null;
      onChange(value);
      if (onDateChange) {
        onDateChange(value);
      }
    },
    [onChange, onDateChange]
  );

  const value =
    dateValue !== undefined && dateValue !== null && dateValue !== '' ? new Date(dateValue) : null;

  return (
    <ThemeProvider theme={calendarTheme}>
      <div className={classes.root}>
        <MuiDatePicker
          {...rest}
          {...inputProps}
          onChange={handleOnChange}
          value={value}
          inputRef={ref}
          fullWidth={fullWidth}
          size={size}
          invalidDateMessage={invalidDateMessage}
          variant={variant}
          clearable={clearable}
          format={format}
          inputVariant={inputVariant}
          autoOk={autoOk}
          okLabel={okLabel}
          cancelLabel={cancelLabel}
          orientation={orientation}
          clearLabel={clearLabel}
          InputProps={{
            endAdornment: (
              <IconSprite width="16px" color={theme.palette.text.primary} icon="calendar" />
            ),
          }}
        />
        {error && <FormHelperText error>{error.message}</FormHelperText>}
      </div>
    </ThemeProvider>
  );
}
