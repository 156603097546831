import {
  Checkbox,
  Chip,
  IconButton,
  Paper,
  Portal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  Button,
  ChangeStatusForm,
  formatMoney,
  IconCheckbox,
  IconSprite,
  ModalForm,
  useModalForm,
  User,
  useToast,
} from 'components';
import { QuotaAsset } from './QuotaAsset';
import { useQuotaList } from './useQuotaList';
import { formatDate } from '../utils';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { themeOrange as theme } from 'theme';
import clsx from 'clsx';
import { QuotaAction } from './types';
import { DealQualificationStatus, QuotaStatus } from 'schema';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useSetQuotaStatusMutation } from '../../../services';
import { useQueryClient } from 'react-query';
import { Nomenclatures } from './Nomenclatures';

const baseUrl = '/quotas/calculator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      '& .MuiTableCell-root': {
        '&:first-child': {
          paddingLeft: 0,
          '& a': {
            paddingLeft: theme.spacing(1.5),
          },
        },
        '&:last-child': {
          padding: theme.spacing(2, 1.5, 5, 0.75),
          '& a': {
            padding: 0,
          },
        },
      },
      '& .MuiTableBody-root .MuiTableCell-root': {
        padding: 0,
        position: 'relative',
        '& a': {
          display: 'block',
          padding: theme.spacing(2, 0.75, 5),
          color: theme.palette.common.black,
        },
        '&$cellChat': {
          padding: theme.spacing(2, 0, 5),
          '& a': {
            display: 'block',
            padding: 0,
          },
        },
        '&$cellInfo': {
          padding: theme.spacing(2, 0, 5),
          '& a': {
            display: 'block',
            padding: 0,
          },
        },
      },
    },
    row: {
      '&&:hover': {
        backgroundColor: (props: any) => {
          return props.isLocked ? theme.palette.lightGreen.main : undefined;
        },
      },
      borderLeft: (props: any) => {
        return props.dealQualificationStatus === DealQualificationStatus.Qualified
          ? '4px solid' + theme.palette.info.main
          : props.dealQualificationStatus === DealQualificationStatus.Valid
          ? '4px solid' + theme.palette.warning.light
          : '4px solid transparent';
      },
    },
    id: {
      display: 'block',
      width: '100%',
      height: '100%',
    },
    button: {
      width: '14px',
      padding: 0,
      '&:after': {
        content: '""',
        pointerEvents: 'none',
        display: 'block',
        position: 'absolute',
        left: 9,
        top: 0,
        borderRadius: '50%',
        backgroundColor: 'transparent',
        width: 6,
        height: 6,
      },
      '&$commented:after': {
        backgroundColor: theme.palette.green.main,
      },
    },
    commented: {},
    lessee: {
      [theme.breakpoints.up('md')]: {
        width: '188px',
        maxWidth: '188px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '280px',
        minWidth: '280px',
      },
      [theme.breakpoints.up('xl')]: {
        width: '410px',
        minWidth: '410px',
      },
    },
    owner: {
      width: '148px',
      [theme.breakpoints.up('xl')]: {
        width: '212px',
      },
    },
    dealer: {
      [theme.breakpoints.up('md')]: {
        Width: '188px',
        maxWidth: '188px',
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: '250px',
      },
      [theme.breakpoints.up('xl')]: {
        width: '410px',
        minWidth: '410px',
      },
    },
    link: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    closed: {
      backgroundColor: theme.palette.lightGreen.main,
    },
    opened: {
      backgroundColor: `${theme.palette.attention.main}10`,
    },
    inProgress: {
      backgroundColor: `${theme.palette.attention.main}10`,
    },
    returned: {
      backgroundColor: `${theme.palette.lightPurple.main}50`,
    },
    postponed: {},
    forReview: {},
    verification: {},
    rework: {},
    inQueue: {},
    processRegistration: {
      backgroundColor: theme.palette.secondary.light,
    },
    quotaAsset: {
      display: 'inline-block',
    },
    cellInfo: {
      width: '24px',
    },
    cellChat: {
      width: '24px',
    },
    cellHamburger: {
      '&&&': {
        width: '24px',
        paddingTop: 15,
        paddingLeft: 0,
      },
    },
    cellID: {
      maxWidth: '60px',
    },
    quotaStatus: {
      height: 19,
      position: 'absolute',
      right: 8,
      borderRadius: 4,
      bottom: 12,
      fontSize: 11,
      padding: theme.spacing(0, 1),
      lineHeight: 1,
      backgroundColor: 'red',
      color: theme.palette.attention.main,
      '& .MuiChip-label': {
        padding: 0,
      },
      '&$lost': {
        backgroundColor: '#FFF6E2',
        color: theme.palette.attention.main,
      },
      '&$archived': {
        backgroundColor: theme.palette.bgGray.main,
        color: theme.palette.text.secondary,
      },
    },
    lost: {},
    archived: {},
    actions: {
      [theme.breakpoints.down(1200)]: {
        width: 'calc(100% - 85px)',
        left: 63,
      },
      display: 'flex',
      justifyContent: 'flex-start',
      width: 'calc(100% - 238px)',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      left: 216,
      position: 'fixed',
      zIndex: 20,
    },
    actionButton: {
      marginRight: theme.spacing(2.5),
    },
  })
);

type QuotasTableProps = Omit<ReturnType<typeof useQuotaList>, 'filter' | 'paging' | 'tabs'>;

type TableItemProps = QuotasTableProps['rows'][0] & {
  selectedQuotas: number[];
  setSelectedQuotas: Dispatch<SetStateAction<number[]>>;
};

const TableItem = (props: TableItemProps) => {
  const classes = useStyles(props);

  const { t } = useTranslation();

  const {
    id,
    action,
    currency,
    lessee,
    createdDate,
    fundingAmountNBV,
    dealer,
    asset,
    user: { id: userId, name, isActive },
    issueStatus,
    telematicsIssueId,
    telematicsIssueStatus,
    shipmentIssueId,
    shipmentIssueStatus,
    returnReason,
    contractNumber,
    hasComments,
    status,
    selectedQuotas,
    setSelectedQuotas,
    nomenclatures,
    scoringModelType,
  } = props;

  const quotaAssetProps = {
    asset,
    currency,
    issueStatus,
    returnReason,
    telematicsIssueId,
    telematicsIssueStatus,
    shipmentIssueId,
    shipmentIssueStatus,
    contractNumber,
    nomenclatures,
  };

  const actionCalculation = action as QuotaAction | undefined;

  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      <TableRow
        className={clsx(
          classes.row,
          issueStatus && classes[issueStatus] ? classes[issueStatus] : ''
        )}
      >
        <TableCell align="center" size="medium">
          <Checkbox
            icon={<IconCheckbox checked={false} />}
            checkedIcon={<IconCheckbox checked={true} />}
            checked={selectedQuotas.indexOf(id) > -1}
            onClick={() => {
              setSelectedQuotas((prev: number[]) => {
                const index = prev.indexOf(id);
                if (index === -1) {
                  return [...prev, id];
                } else {
                  prev.splice(index, 1);
                  return [...prev];
                }
              });
            }}
          />
        </TableCell>
        <TableCell className={classes.cellID} align="right" size="medium">
          <Link className={classes.id} to={`${baseUrl}/${id}`}>
            {id}
          </Link>
        </TableCell>
        <TableCell size="medium">
          <Link className={classes.quotaAsset} to={`${baseUrl}/${id}`}>
            {lessee ?? t('Not set')}
            <QuotaAsset {...quotaAssetProps} setShow={setShow} show={show} />
          </Link>
        </TableCell>
        <TableCell className={classes.dealer} size="medium">
          <Link to={`${baseUrl}/${id}`}>{dealer}</Link>
        </TableCell>
        <TableCell className={classes.dealer} align="right" size="medium">
          <Link to={`${baseUrl}/${id}`}>
            {formatMoney({ amount: fundingAmountNBV, currency: 'RUB' })}
          </Link>
        </TableCell>
        <TableCell align="right" size="medium">
          <Link to={`${baseUrl}/${id}`}>{formatDate(createdDate, true)}</Link>
        </TableCell>
        <TableCell key={userId} size="medium">
          <Link to={`${baseUrl}/${id}`}>
            <User name={name} isActive={isActive} />
          </Link>
        </TableCell>
        <TableCell className={classes.cellChat} size="medium">
          <IconButton
            aria-label="open chat panel"
            component={Link}
            to={`${baseUrl}/comments/${id}?lessee=${lessee}&name=${asset.name}`}
            className={clsx(classes.button, hasComments ? classes.commented : '')}
          >
            <IconSprite width="14px" icon="chat" color={theme.palette.text.primary} />
          </IconButton>
        </TableCell>
        <TableCell className={classes.cellInfo} size="medium">
          {actionCalculation === 'changeOwner' || actionCalculation === 'viewHistory' ? (
            <IconButton
              aria-label="view info"
              component={Link}
              to={`${baseUrl}/owner/${id}`}
              className={classes.button}
            >
              <IconSprite width="16px" icon="menu-catalogs" color={theme.palette.text.primary} />
            </IconButton>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell className={classes.cellHamburger} size="medium">
          <IconButton
            aria-label="show nomenclatures"
            onClick={() => setShow((prev) => !prev)}
            className={classes.button}
          >
            <IconSprite width="12px" icon="switcher-list" color={theme.palette.text.primary} />
          </IconButton>
          {status === QuotaStatus.Lost ? (
            <Chip label="Потерян" className={clsx(classes.quotaStatus, classes.lost)} />
          ) : status === QuotaStatus.Archived ? (
            <Chip label="Архив" className={clsx(classes.quotaStatus, classes.archived)} />
          ) : null}
        </TableCell>
      </TableRow>
      {show && quotaAssetProps.nomenclatures.length > 0 && (
        <Nomenclatures
          nomenclatures={quotaAssetProps.nomenclatures}
          scoringModelType={scoringModelType}
        />
      )}
    </>
  );
};

export const QuotasTable = (props: QuotasTableProps) => {
  const { rows } = props;
  const { t } = useTranslation();
  const toast = useToast();
  const classes = useStyles(props);
  const [selectedQuotas, setSelectedQuotas] = useState<number[]>([]);
  const { onOpen, onClose, open } = useModalForm();
  const queryClient = useQueryClient();

  const { mutateAsync } = useSetQuotaStatusMutation({
    onSuccess: () => {
      toast(t('Status changed'), 'success');
      setSelectedQuotas([]);
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('quotas') > -1;
        },
      });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const activeHandler = useCallback(() => {
    mutateAsync({
      quotaId: selectedQuotas,
    });
  }, [selectedQuotas, mutateAsync]);

  const archivedHandler = useCallback(() => {
    mutateAsync({
      quotaId: selectedQuotas,
      status: QuotaStatus.Archived,
    });
  }, [selectedQuotas, mutateAsync]);

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell size="medium"></TableCell>
            <TableCell className={classes.cellID} size="medium" align="right">
              {t('ID')}
            </TableCell>
            <TableCell className={classes.lessee} size="medium">
              {t('Lessee')}
            </TableCell>
            <TableCell className={classes.dealer} size="medium">
              {t('Dealer')}
            </TableCell>
            <TableCell align="right" size="medium">
              {t('NBV')}
            </TableCell>
            <TableCell align="right" size="medium">
              {t('Date')}
            </TableCell>
            <TableCell className={classes.owner} size="medium">
              {t('Owner')}
            </TableCell>
            <TableCell size="medium" />
            <TableCell size="medium" />
            <TableCell size="medium" />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableItem
              key={row.id}
              {...row}
              selectedQuotas={selectedQuotas}
              setSelectedQuotas={setSelectedQuotas}
            />
          ))}
        </TableBody>
      </Table>
      {selectedQuotas.length > 0 && (
        <Portal container={document.body}>
          <Paper square className={classes.actions}>
            <div className={classes.actionButton}>
              <Button
                color="primary"
                size="medium"
                type="submit"
                variant="outlined"
                onClick={activeHandler}
              >
                {t('IsActive')}
              </Button>
            </div>
            <div className={classes.actionButton}>
              <Button
                color="primary"
                size="medium"
                type="button"
                variant="outlined"
                onClick={onOpen}
              >
                {t('Lost')}
              </Button>
            </div>
            <div className={classes.actionButton}>
              <Button
                color="primary"
                size="medium"
                type="submit"
                variant="outlined"
                onClick={archivedHandler}
              >
                {t('QuotaStatus.Archived')}
              </Button>
            </div>
          </Paper>
        </Portal>
      )}
      <ModalForm open={open} onClose={onClose}>
        <ChangeStatusForm
          onSuccess={onClose}
          quotaId={selectedQuotas}
          setSelectedQuotas={setSelectedQuotas}
        />
      </ModalForm>
    </>
  );
};
