import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { formatMoney } from 'components/utils';
import { Currency, DealQualificationStatus } from 'schema/serverTypes';
import { IssueQuota } from './useIssuesData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.palette.common.white,
      backgroundColor: (props: any) => {
        return props.dealQualificationStatus === DealQualificationStatus.Qualified
          ? theme.palette.info.main
          : props.dealQualificationStatus === DealQualificationStatus.Valid
          ? theme.palette.warning.light
          : theme.palette.secondary.main;
      },
      padding: theme.spacing(0.5, 1.5),
      '&:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
    },
    groupTitle: {
      fontSize: 12,
    },
    groupTime: {
      fontSize: 12,
      backgroundColor: theme.palette.secondary.main,
    },
  })
);

export const IssueQuotaListItem = (props: IssueQuota) => {
  const classes = useStyles(props);
  const { quotaId, contractNumber, lesseeName, fundingAmountNBV, contractStatus } = props;

  return (
    <div className={classes.groupContainer}>
      <div className={classes.groupTitle}>
        Расчет {quotaId} | {contractNumber} | {lesseeName}{' '}
        {fundingAmountNBV !== undefined
          ? ` | NBV ${formatMoney({
              amount: fundingAmountNBV,
              currency: Currency.Ruble,
            })} `
          : null}
        {contractStatus !== undefined && contractStatus !== '' ? `| ${contractStatus} ` : null}
      </div>
      <div className={classes.groupTime}>{/*group.time*/}</div>
    </div>
  );
};
