import { CounterpartyType } from 'schema/serverTypes';
import { QuotaChipsProps } from './types';
import { OwnersChips } from './OwnersChips';
import { CounterpartyChips } from './CounterpartyChips';
import { ExpectedShipmentDateChips } from './ExpectedShipmentDateChips';
import { LeasingProductChips } from './LeasingProductChips';
import { StatusChips } from './StatusChips';
import { ShipmentDateChips } from './ShipmentDateChips';

export const QuotaChips = (props: QuotaChipsProps) => {
  return (
    <>
      <CounterpartyChips {...props} type={CounterpartyType.lessee} />
      <CounterpartyChips {...props} type={CounterpartyType.dealer} />
      <CounterpartyChips {...props} />
      <LeasingProductChips {...props} />
      <ExpectedShipmentDateChips {...props} />
      <ShipmentDateChips {...props} />
      <OwnersChips {...props} />
      <StatusChips {...props} />
    </>
  );
};
