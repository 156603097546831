import { IconSprite } from '../../icons';
import { themeOrange as theme } from 'theme';
import { calculationUrl } from 'services/urls';
import { useBackendFetch } from '../../../services';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { WikiFileViewModel } from '../../../schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      position: 'absolute',
      right: 1,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      '& *': {
        marginRight: 9,
        cursor: 'pointer',
      },
    },
    file: {
      textAlign: 'left',
      cursor: 'pointer',
      position: 'relative',
      margin: theme.spacing(0, 1.5, 1),
      padding: theme.spacing(1.5, 1.25, 1),
      backgroundColor: theme.palette.lightBlue3.main,
      color: theme.palette.primary.main,
    },
  })
);

type FileListProps = {
  editMode?: boolean;
  files: (WikiFileViewModel | File)[];
  onDelete?: any;
};

type unifiedFileProps = {
  id: string;
  fileName: string;
  downloadUrl?: string;
};

export const FileList = (props: FileListProps) => {
  const classes = useStyles();
  const { files, editMode = false, onDelete } = props;
  const unifiedFiles = files.map((file) => {
    return file.hasOwnProperty('downloadUrl')
      ? {
          fileName: (file as WikiFileViewModel).fileName,
          id: (file as WikiFileViewModel).id,
          downloadUrl: (file as WikiFileViewModel).downloadUrl,
        }
      : {
          fileName: (file as File).name,
          id: (file as File).name,
        };
  });

  const fetchBackend = useBackendFetch();

  const getFile = (file: any) => async () => {
    if (!file.downloadUrl) return;
    const response = await fetchBackend(`${calculationUrl}${file.downloadUrl}`);
    const result = await response.blob();
    const blob = new Blob([result], {
      type: result.type,
    });
    const newUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = newUrl;
    if (file) {
      a.download = file.fileName;
    }
    document.body.appendChild(a);
    a.click();
  };

  return (
    <>
      {(unifiedFiles as unifiedFileProps[]).map((file) => (
        <div className={classes.file} key={file.id}>
          {file.fileName}
          <div className={classes.actions}>
            {file.downloadUrl && !editMode && (
              <div onClick={getFile(file)}>
                <IconSprite
                  icon="download"
                  width="14px"
                  height="13px"
                  color={theme.palette.text.primary}
                  hoverColor={theme.palette.primary.main}
                />
              </div>
            )}
            {editMode && (
              <div onClick={(ev) => onDelete(file.fileName, ev)}>
                <IconSprite
                  icon="delete2"
                  width="14px"
                  height="14px"
                  color={theme.palette.text.primary}
                  hoverColor={theme.palette.primary.main}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};
