import { useCallback } from 'react';
import { QueryKey, useMutation } from 'react-query';
import { createQuotasBackendUrl } from './createQuotasBackendUrl';
import {
  useBackendQuery,
  QueryOptions,
  MutationOptions,
  useBackendFetch,
  useBackendMutation,
} from '.';
import {
  CloseNomenclatureIssueViewModel,
  CloseScoringApprovalIssueViewModel,
  IssueFileViewModel,
  IssueViewModel,
  OpenAgainIssueModel,
  ReturnIssueModel,
  UpdateControlViewModel,
  UpdateIssueStatusModel,
  ValidationProblemDetails,
  ScoringIssueDashboardModel,
  ReturnScoringApprovalIssueViewModel,
  CancelScoringApprovalIssueViewModel,
  OpenAgainScoringApprovalIssueViewModel,
  ApproveMarginIssueViewModel,
} from 'schema/serverTypes';
import { calculationUrl } from 'services/urls';
import { EmptyResponse } from './types';
import { appendValue, useBackendFormDataMutation, useDownloadFile } from './useBackend';

const createOptions = <TResponse, TQueryKey extends QueryKey = QueryKey>(
  options: QueryOptions<TResponse, TQueryKey> | undefined = {}
) => {
  const defaultOptions: QueryOptions<TResponse, TQueryKey> = {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    refetchInterval: false,
  };
  return {
    ...defaultOptions,
    ...options,
  } as QueryOptions<TResponse, TQueryKey>;
};

export type IssuesBackendQueryOptions<TResponse> = {
  relativeUrl?: string;
  searchParams?: URLSearchParams;
  options?: QueryOptions<TResponse, string>;
  queryKey?: string;
};

export type IssueBackendQueryOptions = {
  relativeUrl?: string;
  queryKey?: string;
};

export const useIssuesBackendQuery = <TResponse>(options: IssuesBackendQueryOptions<TResponse>) => {
  const { relativeUrl, searchParams, queryKey, options: queryOptions } = options;
  const url = createQuotasBackendUrl(relativeUrl, searchParams);
  return useBackendQuery(url, queryKey ?? url, createOptions(queryOptions));
};

export const useIssueBackendQuery = <TResponse, TError = unknown>(
  options: IssueBackendQueryOptions
) => {
  const { relativeUrl, queryKey } = options;
  const url = createQuotasBackendUrl(relativeUrl);
  return useBackendQuery<TResponse, string, TError>(url, queryKey ?? url);
};

export const useIssuesFileUploadMutation = <TRequest, TResponse, TContext = unknown>(
  relativeUrl: string,
  getFormData: (request: TRequest) => FormData,
  options: MutationOptions<TRequest, TResponse, TContext> | undefined = { method: 'POST' }
) => {
  const fetchBackend = useBackendFetch(false);
  const requestUrl = createQuotasBackendUrl(relativeUrl);

  const { method = 'POST', ...rest } = options;

  const mutationFn = useCallback(
    async (form: TRequest): Promise<TResponse> => {
      const requestMethod =
        method === undefined ? 'POST' : typeof method === 'string' ? method : method(form);
      const response = await fetchBackend(requestUrl, {
        method: requestMethod,
        body: getFormData(form),
      });

      if (response.status === 400) {
        const validationErrors: ValidationProblemDetails | undefined = await response.json();
        throw validationErrors ?? { errors: { '': ['Ошибка'] } };
      }
      if (response.status === 500) {
        const error: ValidationProblemDetails = { errors: { '': ['Ошибка'] } };
        throw error;
      }

      const result: TResponse = await response.json();
      return result;
    },
    [fetchBackend, method, requestUrl, getFormData]
  );

  return useMutation(mutationFn, rest);
};

export const useIssueBackendMutation = <TRequest, TResponse, TContext = unknown>(
  relativeUrl: string | ((form: TRequest) => string),
  options?: MutationOptions<TRequest, TResponse, TContext>
) => useBackendMutation(`${calculationUrl}/api/v1/issues/${relativeUrl}`, options);

export const useIssueDeleteMutation = <TRequest, TResponse, TContext = unknown>(
  issueId: number | ((form: TRequest) => string),
  options?: MutationOptions<TRequest, TResponse, TContext>
) =>
  useBackendMutation(`${calculationUrl}/api/v1/issues/${issueId}`, {
    method: 'DELETE',
    ...options,
  });

export const useIssueContractMutation = <TRequest, TResponse, TContext = unknown>(
  quotaId: number | ((form: TRequest) => string),
  options?: MutationOptions<TRequest, TResponse, TContext>
) =>
  useBackendMutation(`${calculationUrl}/api/v1/quotas/${quotaId}/execute`, {
    method: 'PUT',
    ...options,
  });

export const useIssueReturnMutation = (
  issueId: number | ((form: ReturnIssueModel) => string),
  options?: MutationOptions<ReturnIssueModel, IssueViewModel>
) =>
  useBackendMutation(`${calculationUrl}/api/v1/issues/${issueId}/return`, {
    method: 'PUT',
    ...options,
  });

export const useIssueOpenAgainMutation = (
  issueId: number | ((form: OpenAgainIssueModel) => string),
  options?: MutationOptions<OpenAgainIssueModel, IssueViewModel>
) =>
  useBackendMutation(`${calculationUrl}/api/v1/issues/${issueId}/openAgain`, {
    method: 'PUT',
    ...options,
  });

export const useSetContractFolderUrlMutation = (
  issueId: number | ((form: OpenAgainIssueModel) => string),
  options?: MutationOptions<OpenAgainIssueModel, IssueViewModel>
) =>
  useBackendMutation(`${calculationUrl}/api/v1/issues/${issueId}/contractFolderUrl`, {
    method: 'PUT',
    ...options,
  });

export const useUpdateIssueStatusMutation = (
  issueId: number,
  options?: MutationOptions<UpdateIssueStatusModel, IssueViewModel>
) =>
  useIssueBackendMutation<UpdateIssueStatusModel, IssueViewModel>(`${issueId}/status`, {
    method: 'PUT',
    ...options,
  });

export const useIssueVerificateMutation = (
  issueId: number,
  options?: MutationOptions<unknown, IssueViewModel | undefined>
) =>
  useBackendMutation(`${calculationUrl}/api/v1/issues/${issueId}/verification`, {
    method: 'POST',
    ...options,
  });

export const useUpdateContolMutation = (
  issueId: number,
  controlId: number,
  options?: MutationOptions<UpdateControlViewModel, EmptyResponse>
) =>
  useBackendMutation(`${calculationUrl}/api/v1/issues/${issueId}/controls/${controlId}`, {
    method: 'POST',
    ...options,
  });

export const useCloseNomenclatureIssueMutation = (
  issueId: number,
  options?: MutationOptions<CloseNomenclatureIssueViewModel, IssueViewModel | undefined>
) =>
  useBackendMutation(`${calculationUrl}/api/v1/issues/nomenclatures/${issueId}`, {
    method: 'POST',
    ...options,
  });

export const useCloseScoringApprovalIssueMutation = (
  issueId: number,
  options?: MutationOptions<CloseScoringApprovalIssueViewModel, IssueViewModel | undefined>
) =>
  useBackendFormDataMutation(
    `${calculationUrl}/api/v1/issues/scoring/approval/${issueId}`,
    (values: CloseScoringApprovalIssueViewModel) => {
      const formData = new FormData();

      appendValue(formData, 'status', values.status);
      appendValue(formData, 'description', values.description);

      if (values.file) {
        formData.append('file', values.file);
      }

      return formData;
    },
    {
      method: 'POST',
      ...options,
    }
  );

export const useReturnScoringApprovalIssueMutation = (
  issueId: number,
  options?: MutationOptions<ReturnScoringApprovalIssueViewModel, IssueViewModel | undefined>
) =>
  useBackendFormDataMutation(
    `${calculationUrl}/api/v1/issues/scoring/approval/${issueId}/return`,
    (values: ReturnScoringApprovalIssueViewModel) => {
      const formData = new FormData();

      appendValue(formData, 'description', values.description);

      if (values.file !== null) {
        formData.append('file', values.file);
      }

      return formData;
    },
    {
      method: 'POST',
      ...options,
    }
  );

export const useOpenAgainScoringApprovalIssueMutation = (
  issueId: number,
  options?: MutationOptions<OpenAgainScoringApprovalIssueViewModel, IssueViewModel | undefined>
) =>
  useBackendFormDataMutation(
    `${calculationUrl}/api/v1/issues/scoring/approval/${issueId}/reopen`,
    (values: ReturnScoringApprovalIssueViewModel) => {
      const formData = new FormData();

      appendValue(formData, 'description', values.description);

      if (values.file !== null) {
        formData.append('file', values.file);
      }

      return formData;
    },
    {
      method: 'POST',
      ...options,
    }
  );

export const useCancelScoringApprovalIssueMutation = (
  issueId: number,
  options?: MutationOptions<CancelScoringApprovalIssueViewModel, IssueViewModel | undefined>
) =>
  useBackendFormDataMutation(
    `${calculationUrl}/api/v1/issues/scoring/approval/${issueId}/cancel`,
    (values: CancelScoringApprovalIssueViewModel) => {
      const formData = new FormData();

      appendValue(formData, 'description', values.description);

      if (values.file !== null) {
        formData.append('file', values.file);
      }

      return formData;
    },
    {
      method: 'POST',
      ...options,
    }
  );

export const useCloseNomenclatureIssueAgainMutation = (
  issueId: number,
  options?: MutationOptions<CloseNomenclatureIssueViewModel, IssueViewModel | undefined>
) =>
  useBackendMutation(`${calculationUrl}/api/v1/issues/nomenclatures/${issueId}`, {
    method: 'PUT',
    ...options,
  });

export const useNotifyIncidentMutation = (options?: any) =>
  useBackendMutation(`${calculationUrl}/api/v1/issues/incident`, {
    method: 'PUT',
    ...options,
  });

export const useDownloadScoringApprovalFileRequest = (props: IssueFileViewModel) => {
  const { downloadUrl, fileName } = props;

  return useDownloadFile({
    downloadUrl,
    fileName,
  });
};

export const useScoringIssueDashboardRequest = (tabId: string) => {
  const searchParams = new URLSearchParams();
  searchParams.set('tabId', tabId);

  return useIssuesBackendQuery<ScoringIssueDashboardModel>({
    relativeUrl: `scoring/dashboard/${tabId}`,
    searchParams,
    options: {
      refetchOnMount: true,
    },
  });
};

export const useApproveMarginIssueMutation = (
  issueId: number,
  options?: MutationOptions<ApproveMarginIssueViewModel, IssueViewModel | undefined>
) =>
  useBackendMutation(`${calculationUrl}/api/v1/issues/margin/${issueId}`, {
    method: 'PUT',
    ...options,
  });
