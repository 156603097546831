import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { ScoringApprovalViewModel, ScoringModelApprovalStatus } from 'schema';
import dayjs from 'dayjs';
import { IconSprite } from '../../icons';
import { themeOrange as theme } from 'theme';
import { calculationUrl, useBackendFetch } from '../../../services';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { User } from '../../users';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: '16px 1fr 1fr 6fr 1fr 1fr 1fr 2fr',
      fontSize: 12,
      padding: theme.spacing(1, 2),
      gridGap: theme.spacing(0, 2),
      color: theme.palette.text.secondary,
      borderLeft: `4px solid transparent`,
      '&$first': {
        backgroundColor: '#F0EFEE',
        borderLeft: `4px solid ${theme.palette.text.primary}`,
        color: theme.palette.text.primary,
        fontWeight: 600,
        padding: theme.spacing(3, 2),
        '&$rejected': {
          backgroundColor: '#FFEEE6',
          borderLeft: `4px solid ${theme.palette.error.main}`,
        },
        '&$approved': {
          backgroundColor: theme.palette.success.light,
          borderLeft: `4px solid ${theme.palette.success.main}`,
        },
        '&$sentToRework': {
          backgroundColor: theme.palette.primaryGradient[100],
          borderLeft: `4px solid ${theme.palette.primary.main}`,
        },
      },
    },
    first: {},
    rejected: {},
    approved: {},
    sentToRework: {},
    link: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
    decision: {
      display: 'flex',
    },
    viewIcon: {
      cursor: 'pointer',
    },
  }),
);

type ScoringModelApprovalStatusProps = {
  row: ScoringApprovalViewModel;
};

const ApprovalStatus = (props: ScoringModelApprovalStatusProps) => {
  const classes = useStyles();
  const { isAutomatic, result, issue, status } = props.row;
  const isValidString = result.isValid ? 'Одобрен' : 'Отклонён';

  const statusString =
    status === undefined
      ? isValidString
      : status === ScoringModelApprovalStatus.approved
        ? 'Одобрен'
        : status === ScoringModelApprovalStatus.rejected
          ? 'Отклонён'
          : isValidString;
  //хотели отображение только двух сутусов Отклонён/Одобрен
  //const statusString =
  //  status === undefined
  //    ? isValidString
  //    : status === ScoringModelApprovalStatus.inProgress
  //    ? 'В работе'
  //    : status === ScoringModelApprovalStatus.approved
  //    ? 'Одобрено'
  //    : status === ScoringModelApprovalStatus.sentToRework
  //    ? 'На доработку'
  //    : status === ScoringModelApprovalStatus.rejected
  //    ? 'Отклонено'
  //    : status === ScoringModelApprovalStatus.cancelled
  //    ? 'Отменено'
  //    : status === ScoringModelApprovalStatus.outdated
  //    ? 'Просрочено'
  //    : isValidString;

  if (isAutomatic) {
    return <>&nbsp;{statusString}</>;
  }

  if (issue) {
    return (
      <>
        &nbsp;{statusString}&nbsp;|&nbsp;
        <Link to={`/tasks/${issue.id}`} className={classes.link}>
          №{issue.id}
        </Link>
      </>
    );
  }

  return <>&nbsp;{statusString}</>;
};

export type StatusItemProps = {
  row: ScoringApprovalViewModel;
  index: number;
};

export const StatusItem = (props: StatusItemProps) => {
  const classes = useStyles();
  const { row, index } = props;

  const isHand = !row.isAutomatic;

  const downloadUrl = row.issue?.file?.downloadUrl;
  const fileName = row.issue?.file?.fileName;

  const fetchBackend = useBackendFetch();
  const getFile = useCallback(
    async (ev) => {
      ev.stopPropagation();
      const response = await fetchBackend(`${calculationUrl}${downloadUrl}`);
      const result = await response.blob();
      const blob = new Blob([result], {
        type: result.type,
      });
      const newUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = newUrl;
      if (fileName) {
        a.download = fileName;
      }
      document.body.appendChild(a);
      a.click();
    },
    [fetchBackend, fileName, downloadUrl],
  );

  const { push } = useHistory();
  const { pathname } = useLocation();

  const viewHandler = useCallback(() => {
    push(`${pathname}/scoringmodel`, row);
  }, [push, pathname, row]);

  const statusClass = (status: ScoringModelApprovalStatus) => {
    switch (status) {
      case ScoringModelApprovalStatus.rejected:
        return classes.rejected;
      case ScoringModelApprovalStatus.approved:
        return classes.approved;
      case ScoringModelApprovalStatus.sentToRework:
        return classes.sentToRework;
      default:
        return '';
    }
  };

  return (
    <div
      className={clsx(
        classes.root,
        index === 0 && classes.first,
        row.status && statusClass(row.status),
      )}
    >
      <div className={classes.link}>
        {row.issue?.file && (
          <div onClick={getFile}>
            <IconSprite
              icon={'download3'}
              width={16}
              color={index === 0 ? theme.palette.text.primary : theme.palette.text.secondary}
            />
          </div>
        )}
      </div>
      <div>{row.name}</div>
      <div>{isHand ? 'Ручной' : 'Авто'}</div>
      <div className={classes.decision}>
        {row.result.rules && (
          <div onClick={viewHandler} className={classes.viewIcon}>
            <IconSprite
              icon={'view'}
              width={16}
              color={index === 0 ? theme.palette.text.primary : theme.palette.text.secondary}
            />
          </div>
        )}
        <ApprovalStatus row={row} />
      </div>
      <div>{row.createdDate ? dayjs(row.createdDate).format('DD.MM.YYYY') : '—'}</div>
      <div>{row.validToDate ? dayjs(row.validToDate).format('DD.MM.YYYY') : '—'}</div>
      <div>
        {row.issue?.assignee ? (
          <User name={row.issue.assignee.name} isActive={row.issue.assignee.isActive} />
        ) : (
          '—'
        )}
      </div>
    </div>
  );
};
