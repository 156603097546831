import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import { TabPanel, Tabs, useTabs } from 'components/Tabs';
import SwipeableViews from 'react-swipeable-views';
import { ShipmentItem } from './ShipmentItem';
import { ShipmentFilter } from './ShipmentFilter';
import { ModalForm, useModalForm } from '../../Modal';
import { ShipmentForm } from './ShipmentForm';
import { useCurrentUserTabsQuery } from 'services/api/useApplicationUsersBackend';
import { useShipmentItemsReportQuery } from 'services/api/useReportsBackend';
import { ApplicationUserTab, ShipmentGroupItemViewModel } from 'schema/serverTypes';
import { Empty } from '../../Empty';
import { endOfMonth, startOfMonth } from './dates';
import { usePaging } from 'components/paging/usePaging';
import { Paging } from 'components/paging';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1.5, 2.5),
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      paddingRight: theme.spacing(5),
    },
    tabs: {
      marginBottom: -2,
    },
  })
);

type TabContentProps = {
  tabIndex: number;
  index: number;
  tabId: number;
  start?: string;
  end?: string;
  expiredOnly?: boolean;
  onOpen: (item: ShipmentGroupItemViewModel) => void;
};

const TabContent = (props: TabContentProps) => {
  const { index, tabId, start, end, expiredOnly, tabIndex, onOpen } = props;
  const { t } = useTranslation();

  const {
    data: group,
    isLoading,
    refetch,
  } = useShipmentItemsReportQuery(
    {
      tabId,
      start,
      end,
      expiredOnly,
    },
    {
      enabled: index === tabIndex,
    }
  );

  const paging = usePaging({
    pageSize: 10,
  });

  const items = group?.items ?? [];
  const { page, pageSize, onReset } = paging;

  useEffect(() => {
    onReset();
  }, [start, end, expiredOnly, onReset]);

  const pageCount = Math.floor(items.length / pageSize) + (items.length % pageSize > 0 ? 1 : 0);
  const totalCount = items.length;

  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const pageData = items.slice(startIndex, endIndex);
  const dataCount = pageData.length;

  if (isLoading || group === undefined) {
    return <Empty>{t('Loading')}</Empty>;
  }

  if (group.items.length === 0) {
    return <Empty>{t("You don't have scheduled downloads")}</Empty>;
  }

  return (
    <TabPanel value={index} index={index} dir={theme.direction}>
      {pageData.map((item) => {
        return (
          <ShipmentItem
            key={`${item.quotaId}-${item.id}`}
            item={item}
            onOpen={onOpen}
            refetch={refetch}
          />
        );
      })}
      <Paging {...paging} pageCount={pageCount} totalCount={totalCount} dataCount={dataCount} />
    </TabPanel>
  );
};

export const ShipmentContainer = () => {
  const classes = useStyles();
  const [start, setStartDate] = useState<string | undefined>(startOfMonth);
  const [end, setEndDate] = useState<string | undefined>(endOfMonth);
  const [expiredOnly, setExpiredOnly] = useState<boolean>(false);
  const { t } = useTranslation();

  const { data = [], isLoading } = useCurrentUserTabsQuery();

  const groups = data.filter((t) => t.id !== ApplicationUserTab.mine);

  const tabs = useMemo(() => {
    return groups.map((group) => {
      return group.name.length >= 15 ? `${group.name.substr(0, 11)}...` : group.name;
    });
  }, [groups]);
  const tabsProps = useTabs(tabs);
  const { tabIndex, onChangeTabIndex, onChangeTab } = tabsProps;

  const [item, setItem] = useState<ShipmentGroupItemViewModel>();

  const { onOpen, onClose, open } = useModalForm();

  const handleOnClose = useCallback(() => {
    setItem(undefined);
    onClose();
  }, [onClose, setItem]);

  const handleOnOpen = useCallback(
    (item) => {
      setItem(item);
      onOpen();
    },
    [onOpen, setItem]
  );

  return (
    <>
      <Paper className={classes.root}>
        <Box className={classes.header}>
          <Typography variant="subtitle1">{t('Shipment_plural')}</Typography>
          <Tabs
            {...tabsProps}
            value={tabIndex}
            onChangeTab={onChangeTab}
            className={classes.tabs}
          />
        </Box>
        <ShipmentFilter
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setExpiredOnly={setExpiredOnly}
        />
        {isLoading ? (
          <Empty></Empty>
        ) : (
          <>
            <SwipeableViews
              containerStyle={{
                transition: 'transform 0.6s ease-out 0s',
              }}
              springConfig={{
                duration: '0.6s',
                easeFunction: 'transform 0.6s ease-out 0s',
                delay: '0s',
              }}
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={tabIndex}
              onChangeIndex={onChangeTabIndex}
            >
              {groups.map((group, index) => {
                return (
                  <TabContent
                    key={group.id}
                    index={index}
                    tabId={group.id}
                    start={start}
                    end={end}
                    expiredOnly={expiredOnly}
                    tabIndex={tabIndex}
                    onOpen={handleOnOpen}
                  />
                );
              })}
            </SwipeableViews>
          </>
        )}
      </Paper>
      <ModalForm open={open} onClose={handleOnClose} width={780}>
        {item && (
          <ShipmentForm
            item={item}
            quotaId={item.quotaId}
            itemId={item.id}
            shipmentDate={item.shipmentDate}
            onSuccess={handleOnClose}
          />
        )}
      </ModalForm>
    </>
  );
};
