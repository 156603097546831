import { IconSprite } from '../../icons';
import { themeOrange as theme } from 'theme';
import { IssueCommentViewModel, ScoringApprovalIssueCommentStatus } from 'schema';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { calculationUrl, useBackendFetch } from 'services';
import { Avatar } from '../../avatar';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { User } from '../../users';
import dayjs from 'dayjs';
import { useLanguage } from '../../utils/useLanguage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 12,
      border: `1px solid ${theme.palette.background.default}`,
      '&&': {
        margin: theme.spacing(1, 0),
      },
      '&:nth-child(n+3)': {
        filter: 'grayscale(1)',
      },
    },
    title: {
      display: 'grid',
      gridTemplateColumns: '28px auto 1fr',
      gridGap: 16,
    },
    titleContainer: {
      backgroundColor: (props: any) =>
        props.scoringApprovalStatus === 'requested'
          ? theme.palette.primaryGradient[100]
          : props.scoringApprovalStatus === 'approved'
          ? theme.palette.success.light
          : props.scoringApprovalStatus === 'rejected'
          ? '#FBF7F7'
          : '#FCF7E9',
      borderLeft: (props: any) =>
        props.scoringApprovalStatus === 'requested'
          ? `3px solid ${theme.palette.primary.main}`
          : props.scoringApprovalStatus === 'approved'
          ? `3px solid ${theme.palette.success.main}`
          : props.scoringApprovalStatus === 'rejected'
          ? `3px solid ${theme.palette.error.main}`
          : `3px solid ${theme.palette.warning.light}`,
      '&&': {
        borderBottom: 'none',
      },
    },
    user: {},
    date: {
      fontSize: 10,
      color: theme.palette.text.secondary,
    },
    type: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      color: (props: any) =>
        props.scoringApprovalStatus === 'requested'
          ? theme.palette.primary.main
          : props.scoringApprovalStatus === 'approved'
          ? theme.palette.success.main
          : props.scoringApprovalStatus === 'rejected'
          ? theme.palette.error.main
          : theme.palette.warning.light,
    },
    answer: {},
    old: {},
    descriptionWrapper: {
      flexDirection: 'column',
    },
    descriptionTitle: {
      fontSize: 9,
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(0.5),
    },
    description: {
      lineHeight: '20px',
      marginBottom: theme.spacing(1.5),
    },
    file: {
      display: 'grid',
      gridGap: 8,
      gridTemplateColumns: '12px 1fr',
      border: `1px solid ${theme.palette.text.secondary}`,
      color: theme.palette.text.primary,
      fontSize: 12,
      borderRadius: 4,
      padding: theme.spacing(1),
      cursor: 'pointer',
      alignItems: 'center',
    },
    size: {
      fontSize: 12,
    },
  })
);

export const ApprovalItem = (props: IssueCommentViewModel) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const lang = useLanguage();

  const { file, text, author, createdDate, scoringApprovalStatus } = props;

  const fetchBackend = useBackendFetch();
  const getFile = useCallback(
    async (ev) => {
      ev.stopPropagation();
      const response = await fetchBackend(`${calculationUrl}${file?.downloadUrl}`);
      const result = await response.blob();
      const blob = new Blob([result], {
        type: result.type,
      });
      const newUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = newUrl;
      if (file) {
        a.download = file.fileName;
      }
      document.body.appendChild(a);
      a.click();
    },
    [fetchBackend, file]
  );

  const statusText =
    scoringApprovalStatus === ScoringApprovalIssueCommentStatus.Requested
      ? 'Запрос на ручное одобрение'
      : scoringApprovalStatus === ScoringApprovalIssueCommentStatus.Approved
      ? 'Одобрен'
      : scoringApprovalStatus === ScoringApprovalIssueCommentStatus.Returned
      ? 'Вернуть на доработку'
      : scoringApprovalStatus === ScoringApprovalIssueCommentStatus.Rejected
      ? 'Отклонена'
      : '';

  return (
    <Accordion defaultExpanded={false} className={classes.root}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.titleContainer}>
        <div className={classes.title}>
          <div>
            <Avatar size="medium" />
          </div>
          <div>
            <div>
              <User name={author.name} isActive={author.isActive} />
            </div>
            <div className={classes.date}>
              {dayjs(createdDate).locale(lang).format('DD MMM YYYY в HH:mm')}
            </div>
          </div>
          <div className={classes.type}>{statusText}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.descriptionWrapper}>
        <div className={classes.descriptionTitle}>{t('Additional info')}</div>
        <div className={classes.description}>{text}</div>
        {file && (
          <div className={classes.file} onClick={getFile}>
            <IconSprite
              icon="template"
              width={12}
              height={16}
              color={theme.palette.text.secondary}
              hoverColor={theme.palette.text.secondary}
            />
            <div>
              {file.fileName}
              <br />
              <Typography variant={'body2'} color={'textSecondary'} className={classes.size}>
                {(file.size / 1024).toFixed(2)} Кб
              </Typography>
            </div>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
