import { IconSprite } from '../icons';
import { themeOrange as theme } from 'theme';
import { IssueFile } from 'schema';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { calculationUrl, useBackendFetch } from 'services';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionWrapper: {
      borderTop: '1px solid #D1D7E4',
      padding: theme.spacing(2, 2.5),
    },
    descriptionTitle: {
      fontSize: 9,
      color: theme.palette.text.secondary,
    },
    description: {
      '& ol': {
        paddingLeft: theme.spacing(2),
        '& > li': {
          listStyleType: 'decimal',
          marginBottom: theme.spacing(1),
        },
      },
      '& ul': {
        paddingLeft: theme.spacing(2),
        '& > li': {
          listStyleType: 'initial',
          marginBottom: theme.spacing(1),
        },
      },
    },
    actions: {
      position: 'absolute',
      right: 1,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      '& *': {
        marginRight: 9,
        cursor: 'pointer',
      },
    },
    file: {
      cursor: 'pointer',
      position: 'relative',
      margin: theme.spacing(0, 1.5, 1.5),
      padding: theme.spacing(1.5, 1.25, 1),
      backgroundColor: theme.palette.lightBlue3.main,
      color: theme.palette.primary.main,
    },
  })
);

type AdditionalInfoProps = {
  description?: string;
  file?: IssueFile;
};

export const AdditionalInfo = (props: AdditionalInfoProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { description, file } = props;

  const fetchBackend = useBackendFetch();
  const getFile = useCallback(
    async (ev) => {
      ev.stopPropagation();
      const response = await fetchBackend(`${calculationUrl}${file?.downloadUrl}`);
      const result = await response.blob();
      const blob = new Blob([result], {
        type: result.type,
      });
      const newUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = newUrl;
      if (file) {
        a.download = file.fileName;
      }
      document.body.appendChild(a);
      a.click();
    },
    [fetchBackend, file]
  );

  if ((!description || description === '') && !file) return null;

  return (
    <div className={classes.descriptionWrapper}>
      <div className={classes.descriptionTitle}>{t('Additional info')}</div>
      {description && description !== '' && (
        <div className={classes.description} dangerouslySetInnerHTML={{ __html: description }} />
      )}
      {file && (
        <div className={classes.file} onClick={getFile}>
          {file.fileName}
          <div className={classes.actions}>
            <div>
              <IconSprite
                icon="download"
                width="14px"
                height="13px"
                color={theme.palette.text.primary}
                hoverColor={theme.palette.primary.main}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
