import { Switch } from 'components';
import { Control, useWatch } from 'react-hook-form';
import { CalculatorFormValues } from '../types';
import { useTranslation } from 'react-i18next';
import { AgentFeeRecipient } from 'schema';

export type AgentFeeWithNdsSwitchProps = {
  control: Control<CalculatorFormValues, any>;
};

export const AgentFeeWithNdsSwitch = (props: AgentFeeWithNdsSwitchProps) => {
  const { control } = props;

  const { t } = useTranslation();

  const agentFeeRecipient = useWatch({ control, name: 'agentFeeRecipient' });

  return agentFeeRecipient !== AgentFeeRecipient.Individual ? (
    <Switch name="isAgentFeeWithNds" label={t('VAT payer')} type="checkbox" control={control} />
  ) : null;
};
