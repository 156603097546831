import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useController, useWatch, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, IconSprite } from 'components';
import clsx from 'clsx';
import { themeOrange as theme } from 'theme';
import { useBackendFetch } from '../../../services/api';
import { calculationUrl } from 'services/urls';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      padding: theme.spacing(1.5, 1.25, 1),
    },
    empty: {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    full: {
      backgroundColor: theme.palette.lightBlue3.main,
      color: theme.palette.primary.main,
    },
    actions: {
      position: 'absolute',
      right: 1,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      '& *': {
        marginRight: 9,
        cursor: 'pointer',
      },
    },
    error: {
      color: theme.palette.error.main,
      fontSize: 9,
      margin: '4px 0 0',
    },
    errorField: {
      border: `1px solid ${theme.palette.error.main}`,
    },
    choose: {
      marginRight: 2,
    },
  })
);

export type FileUploadProps = {
  control: any;
  setValue: any;
  required?: boolean;
  downloadUrl?: string;
  name: string;
  accept?: string[];
};

export const FileUpload = (props: FileUploadProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isDownloadable, setDownloadable] = useState<boolean>(true);
  const {
    control,
    setValue,
    required = false,
    downloadUrl = '',
    name,
    accept = [
      '.doc',
      '.docx',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      '.xlsx',
    ],
  } = props;

  const fileName = useWatch({
    control,
    name,
  });

  const {
    field: { onChange },
    fieldState: { error },
  } = useController({
    control,
    name: 'file',
    rules:
      required && fileName === ''
        ? {
            required: {
              value: true,
              message: t('Required'),
            },
          }
        : undefined,
  });

  const [errors, setErrors] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      acceptedFiles.forEach((file) => {
        onChange(file);
        const fileName = file.name;
        setValue(name, fileName);
        setDownloadable(false);
      });
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            setErrors(`Максимально допустимый размер 10 Мб.`);
          }

          if (err.code === 'file-invalid-type') {
            setErrors(`Недопустимый тип файла.`);
          }
        });
      });
    },
    accept,
    multiple: false,
    maxSize: 10485760,
  });

  const fetchBackend = useBackendFetch();

  const getFile = useCallback(
    async (ev) => {
      ev.stopPropagation();
      const response = await fetchBackend(`${calculationUrl}${downloadUrl}`);
      const result = await response.blob();
      const blob = new Blob([result], {
        type: result.type,
      });
      const newUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = newUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
    },
    [downloadUrl, fetchBackend, fileName]
  );

  const isEmpty = fileName === '';
  const modeClass = isEmpty ? classes.empty : classes.full;
  const requiredError = error !== undefined ? (error as FieldError).message : undefined;
  const errorClass = errors || requiredError ? classes.errorField : '';

  return (
    <>
      <div {...getRootProps()} className={clsx(classes.root, modeClass, errorClass)}>
        {!isEmpty ? <label>{fileName}</label> : <label>{t('Attach a file')}</label>}
        <input {...getInputProps()} />
        <div className={classes.actions}>
          {isEmpty ? (
            <Button variant="inInput" className={classes.choose}>
              {t('Choose')}
            </Button>
          ) : (
            <>
              {isDownloadable && fileName ? (
                <div onClick={getFile}>
                  <IconSprite
                    icon="download"
                    width="14px"
                    height="13px"
                    color={theme.palette.text.primary}
                    hoverColor={theme.palette.primary.main}
                  />
                </div>
              ) : null}
              <IconSprite
                icon="refresh"
                width="13px"
                height="14px"
                color={theme.palette.text.primary}
                hoverColor={theme.palette.primary.main}
              />
            </>
          )}
        </div>
      </div>
      <p className={classes.error}>
        {requiredError}
        {errors}
      </p>
    </>
  );
};
