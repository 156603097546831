import { Grid } from 'components/Grid';
import {
  ShipmentContainer,
  NewsContainer,
  Critical,
  MissingContainer,
  MissingCommentOverdueFormPanel,
  SupportContainer,
  Role,
  PipelineToggle,
  OverdueContainer,
  InsideSalesContainer,
} from 'components';
import { useTranslation } from 'react-i18next';
import { useErrorContext } from 'components/tasks';
import { ApplicationRole, IssueSource } from 'schema/serverTypes';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ApplicationRoles, useUserRole } from 'services';
import { Route, Switch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SortableList } from '../../components/Home/Dnd/SortableList';
import React from 'react';
import { DragHandle } from '../../components/Home/Dnd/SortableItem';
import { PageTitle } from 'components/utils/pageTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(16),
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: 20,
      textAlign: 'center',
    },
    description: {
      color: theme.palette.common.black,
      fontSize: 16,
      textAlign: 'center',
      maxWidth: 437,
    },
  })
);

export const HomePage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const pageTitle = t('Section.home') + ' - Bumblebee';
  PageTitle(pageTitle);

  useErrorContext({
    title: t('Home'),
    source: IssueSource.home,
  });

  const { hasRole } = useUserRole();

  const [items, setItems] = useState(
    localStorage.dashboardOrderVersion !== '2'
      ? [{ id: 70 }, { id: 10 }, { id: 20 }, { id: 30 }, { id: 40 }, { id: 50 }, { id: 60 }]
      : JSON.parse(localStorage.dashboardOrder)
  );

  useEffect(() => {
    localStorage.setItem('dashboardOrderVersion', '2');
    localStorage.setItem('dashboardOrder', JSON.stringify(items));
  }, [items]);

  return (
    <>
      <Critical />
      <Switch>
        <Route path="/">
          <Route path="/comments/overdue" component={MissingCommentOverdueFormPanel} exact />
        </Route>
      </Switch>
      <Grid container spacing={2.5}>
        {hasRole ? (
          <>
            <SortableList items={items} onChange={setItems}>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <Grid item xs={24} id={70}>
                  <DragHandle />
                  <InsideSalesContainer />
                </Grid>
              </Role>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <Grid item xs={24} id={10}>
                  <DragHandle />
                  <MissingContainer />
                </Grid>
              </Role>
              <Role
                role={[
                  ApplicationRole.Admin,
                  ApplicationRole.SalesSupport,
                  ApplicationRole.InsideSales,
                ]}
              >
                <Grid item xs={24} id={20}>
                  <DragHandle />
                  <SupportContainer />
                </Grid>
              </Role>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <Grid item xs={24} id={30}>
                  <DragHandle />
                  <OverdueContainer />
                </Grid>
              </Role>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <Grid item xs={24} id={40}>
                  <DragHandle />
                  <PipelineToggle />
                </Grid>
              </Role>
              <Role>
                <Grid item xs={24} id={50}>
                  <DragHandle />
                  <NewsContainer />
                </Grid>
              </Role>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <Grid item xs={24} id={60}>
                  <DragHandle />
                  <ShipmentContainer />
                </Grid>
              </Role>
            </SortableList>
          </>
        ) : (
          <Grid item xs={24}>
            <div className={classes.container}>
              <h2 className={classes.title}>{t('Welcome to Bumblebee!')}</h2>
              <div className={classes.description}>{t('24hours')}</div>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};
