import { useCounterpartyRisksQuery } from '../../../services/api/useCounterpartiesRiskBackend';
import { useParams } from 'react-router-dom';
import { ApplicationRole, RatingRiskType, RiskTypeViewModel } from 'schema';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { EditButton } from '../../dictionaries/EditButton';
import { CounterpartyRiskEditPanel } from './CounterpartyRiskEditPanel';
import { useState } from 'react';
import { Role } from '../../authentication';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(2.5),
    },
    risk: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1.5),
      borderTop: (isTotal) =>
        isTotal
          ? '1px solid' + theme.palette.secondary.main
          : '1px solid' + theme.palette.secondary.light,
      '&:first-child': {
        borderTop: 'none',
      },
    },
    name: {
      flex: '1 1 auto',
    },
    type: {
      fontWeight: 700,
    },
    edit: {
      minHeight: 38.6,
      minWidth: 38,
    },
    low: {
      color: theme.palette.success.main,
    },
    medium: {
      color: theme.palette.primary.main,
    },
    high: {
      color: theme.palette.error.main,
    },
  }),
);

export const CounterpartyRisks = () => {
  const classes = useStyles();

  const { inn } = useParams<{ inn: string }>();
  const { data: risks, isLoading } = useCounterpartyRisksQuery(inn);
  if (isLoading) return null;

  return <div className={classes.root}>{risks?.map((risk) => <Risk {...risk} />)}</div>;
};

const Risk = ({ id, name, ratingType, isTotal }: RiskTypeViewModel) => {
  const classes = useStyles(isTotal);

  const [isShow, setIsShow] = useState<boolean>(false);

  const handleOpen = () => {
    setIsShow(true);
  };

  return (
    <>
      <div className={classes.risk}>
        <div className={classes.name}>
          <Typography variant={'subtitle1'}>{name}</Typography>
        </div>
        <div className={classes.type}>
          <Typography variant={'subtitle1'}>
            <RatingTypeColored ratingType={ratingType} />
          </Typography>
        </div>
        <div className={classes.edit}>
          <Role role={[ApplicationRole.Admin, ApplicationRole.Compliance]}>
            {!isTotal && <EditButton onClick={handleOpen} />}
          </Role>
        </div>
      </div>
      <CounterpartyRiskEditPanel
        isShow={isShow}
        setIsShow={setIsShow}
        riskTypeId={id}
        riskName={name}
        ratingType={ratingType}
      />
    </>
  );
};

export const RatingTypeColored = ({ ratingType }: { ratingType: RatingRiskType }) => {
  const classes = useStyles();
  return ratingType === RatingRiskType.none ? (
    <span>Не установлен</span>
  ) : ratingType === RatingRiskType.low ? (
    <span className={classes.low}>Низкий</span>
  ) : ratingType === RatingRiskType.medium ? (
    <span className={classes.medium}>Средний</span>
  ) : ratingType === RatingRiskType.high ? (
    <span className={classes.high}>Высокий</span>
  ) : (
    <span />
  );
};
