import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { Button, Grid, IconSprite, Select, useToast } from 'components';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EventNotificationSettingViewModel, EventNotificationType } from 'schema/serverTypes';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useUpdateEventNotificationSettingsMutation } from 'services';
import React, { useCallback, useMemo } from 'react';
import { Divider, MenuItem } from '@material-ui/core';
import { EmailsInput } from './EmailsInput';
import { themeOrange as theme } from 'theme';
import { useStyles } from './useStyles';

type NotificationSettingsEventsFormProps = {
  defaultValues: EventNotificationSettingViewModel[];
};

export const NotificationSettingsEventsForm = (props: NotificationSettingsEventsFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();

  const { control, handleSubmit, setError, clearErrors } = useForm<{
    root: EventNotificationSettingViewModel[];
  }>({
    mode: 'onBlur',
    defaultValues: { root: props.defaultValues },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'root',
  });

  const { mutateAsync, isLoading } = useUpdateEventNotificationSettingsMutation({
    onSuccess: () => {
      toast(t('SuccessMessage'), 'success');
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: { root: EventNotificationSettingViewModel[] }) => {
      await mutateAsync(form.root);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const onAdd = useCallback(() => {
    append({
      type: EventNotificationType.QuotaCounterpartyWithoutPodFT,
      emails: [],
    });
  }, [append]);

  return (
    <form onSubmit={onSubmit} className={classes.root}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="subtitle1">{t('Email Notification settings for events')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container columnSpacing={2} rowSpacing={2.5}>
            {fields.map((item, index) => {
              return (
                <Grid item xs={24} key={item.id} className={classes.paper}>
                  <Divider light className={classes.divider} />
                  <Grid container columnSpacing={2}>
                    <Grid item md={7} xs={24}>
                      <Select
                        label={t('Type')}
                        control={control}
                        name={`root.${index}.type`}
                        rules={{
                          required: {
                            value: true,
                            message: t('Required'),
                          },
                        }}
                      >
                        <MenuItem value={EventNotificationType.QuotaCounterpartyWithoutPodFT}>
                          {t('EventNotificationType.QuotaCreatedLesseePodFtNone')}
                        </MenuItem>
                        <MenuItem value={EventNotificationType.RequestToIS}>
                          {t('EventNotificationType.RequestToIS')}
                        </MenuItem>
                        <MenuItem value={EventNotificationType.IssueContractIncident}>
                          {t('EventNotificationType.IssueContractIncident')}
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={7} xs={24}>
                      <EmailsInput
                        name={`root.${index}.emails`}
                        control={control}
                        setError={setError}
                        clearErrors={clearErrors}
                        rules={{
                          required: true,
                        }}
                        label={t('Email')}
                      />
                    </Grid>
                    <Grid item mdStart={24} md={1} xs={24}>
                      <Button
                        variant="iconButton"
                        endIcon={
                          <IconSprite
                            icon={'delete'}
                            color={theme.palette.secondary.dark}
                            width={'14px'}
                          />
                        }
                        onClick={() => remove(index)}
                        className={classes.delete}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={24}>
              <Button variant="text" className={classes.add} onClick={onAdd}>
                <Typography variant="subtitle1" color="primary">
                  + {t('Add')}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={24}>
              <Button
                className={classes.button}
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
                disabled={isLoading}
              >
                {t('Save')}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </form>
  );
};
